import { Routes, Route } from 'react-router-dom';
import { useTitle } from 'react-use';

import DeviceCreateClaim from 'components/device-create-claim';
import DeviceCreateConfirmation from 'components/device-create-confirmation';
import DeviceCreateDetails from 'components/device-create-details';
import DeviceCreateSerialNumber from 'components/device-create-serial-number';
import ModalInner from 'components/modal-inner';
import ManageLocations from 'modals/manage-locations';

export default function CreateDevice() {
  useTitle('Enroll Device | Optra');

  return (
    <ModalInner>
      <Routes>
        <Route index element={<DeviceCreateSerialNumber />} />
        <Route path=":provisionedDeviceId/claim" element={<DeviceCreateClaim />} />
        <Route path=":provisionedDeviceId/details" element={<DeviceCreateDetails />} />
        <Route
          path=":provisionedDeviceId/details/manage-locations/*"
          element={<ManageLocations />}
        />
        <Route path=":deviceId/confirmation" element={<DeviceCreateConfirmation />} />
      </Routes>
    </ModalInner>
  );
}
