import cx from 'classnames';
import { isFinite as _isFinite, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { Text } from '@optra/kit';

import Feature, { useFeature } from 'components/feature';
import FeatureToggle from 'components/feature-toggle';
import Input from 'components/input';
import KeyValueInput from 'components/key-value-input';
import Label from 'components/label';
import MultiInput from 'components/multi-input';

export const FIELD_NAMES = {
  sound: 'Sound',
  removableMedia: 'Removable Media',
  storage: 'Storage',
  cameras: 'USB Cameras',
  port: 'Web UI',
  hdmi: 'HDMI',
  hostName: 'Host Name',
  endpointAliases: 'Network Endpoint Alias',
  portBindings: 'Port Bindings',
  tmpfs: 'TMPFS',
  shmSize: 'SHM Size',
  hostNetworking: 'Host Networking',
};

const PORT_BINDING_RANGE = [0, 65535];

const portIsInRange = port =>
  (!isNaN(port) && port >= PORT_BINDING_RANGE[0] && port <= PORT_BINDING_RANGE[1]) ||
  `Port number ${port} is outside the valid range (${PORT_BINDING_RANGE[0]} - ${PORT_BINDING_RANGE[1]})`;

export default function SkillPrivilegesFields({ visible, loading = false, form }) {
  const {
    watch,
    formState: { errors },
    control,
    register,
    defaultValues,
    setValue,
    clearErrors,
  } = form;
  const [hasExposedUIPort, setHasExposedUIPort] = useState(
    _isFinite(parseInt(defaultValues?.port)),
  );
  const [hasStorage, setHasStorage] = useState(defaultValues?.storage?.length > 0);
  const [hasHostName, setHasHostName] = useState(!isEmpty(defaultValues?.hostName));
  const [hasEndpointAlias, setHasEndpointAlias] = useState(
    defaultValues?.endpointAliases?.length > 0,
  );
  const [hasPortBindings, setHasPortBindings] = useState(defaultValues?.portBindings?.length > 0);
  const [hasTmpfs, setHasTmpfs] = useState(defaultValues?.tmpfs?.length > 0);
  const [hasShmSize, setHasShmSize] = useState(_isFinite(parseInt(defaultValues?.shmSize)));

  const portValue = watch('port');
  useEffect(() => {
    if (!isEmpty(portValue) || _isFinite(parseInt(portValue))) setHasExposedUIPort(true);
  }, [portValue]);

  const storageValue = watch('storage');
  useEffect(() => {
    setHasStorage(storageValue?.length > 0);
  }, [storageValue]);

  const hostNameValue = watch('hostName');
  useEffect(() => {
    if (!isEmpty(hostNameValue)) setHasHostName(true);
  }, [hostNameValue]);

  const endpointAliasesValue = watch('endpointAliases');
  useEffect(() => {
    setHasEndpointAlias(endpointAliasesValue?.length > 0);
  }, [endpointAliasesValue]);

  const portBindingsValue = watch('portBindings');
  useEffect(() => {
    setHasPortBindings(portBindingsValue?.length > 0);
  }, [portBindingsValue]);

  const tmpfsValue = watch('tmpfs');
  useEffect(() => {
    setHasTmpfs(tmpfsValue?.length > 0);
  }, [tmpfsValue]);

  const shmSizeValue = watch('shmSize');
  useEffect(() => {
    if (!isEmpty(shmSizeValue) || _isFinite(shmSizeValue)) setHasShmSize(true);
  }, [shmSizeValue]);

  const hostNetworkingEnabled = useFeature('hostNetworking');

  return (
    <div className={cx(visible ? 'block' : 'hidden')}>
      <FeatureToggle
        icon="SpeakerHigh"
        title="Sound"
        description="Allow the skill to utilize the device's sound port to play sounds."
        readOnly={loading}
        {...register('devices.sound')}
      />

      <FeatureToggle
        icon="Disc"
        title="Removable Media"
        description="Allows the skill to use removable media."
        readOnly={loading}
        {...register('removableMedia')}
      />

      <FeatureToggle
        icon="Database"
        title="Storage"
        description="Allow the skill to access the device's filesystem."
        readOnly={loading}
        checked={hasStorage}
        expanded={hasStorage}
        onChange={() => {
          setHasStorage(hasStorage => !hasStorage);
          if (hasStorage) {
            setValue('storage', []);
            clearErrors('storage');
          }
        }}
        errors={[{ storage: errors?.storage }]}
      >
        <Controller
          render={({ field }) => (
            <KeyValueInput {...field} keyLabel="Volume Name" valueLabel="Mount Path" />
          )}
          name="storage"
          control={control}
          disabled={loading}
          defaultValue={defaultValues?.storage || []}
          rules={{
            validate: {
              volumeIsAlphaNumeric: values => {
                if (values?.length || hasStorage) {
                  const p = new RegExp('^[a-zA-Z0-9]*$');
                  return (
                    values?.every(v => p.test(v.key)) === true || 'Volume name must be alphanumeric'
                  );
                }
                return true;
              },
              pathIsDirectory: values => {
                if (values?.length || hasStorage) {
                  const p = new RegExp('^/|(/[w-]+)+$');
                  return (
                    values?.every(v => p.test(v.value)) === true ||
                    'Mount path must be in the shape of /directory'
                  );
                }
                return true;
              },
            },
          }}
        />
      </FeatureToggle>

      <FeatureToggle
        icon="Camera"
        title="USB Cameras"
        description="Allows the skill to use USB cameras."
        readOnly={loading}
        {...register('devices.cameras')}
      />

      <Feature feature="deviceTunnel">
        <FeatureToggle
          icon="Globe"
          title="Web UI"
          description="Expose a web-based user-interface via a port of your choosing."
          readOnly={loading}
          checked={hasExposedUIPort}
          expanded={hasExposedUIPort}
          onChange={() => {
            setHasExposedUIPort(hasExposedUIPort => !hasExposedUIPort);
            if (hasExposedUIPort) {
              setValue('port', '');
              clearErrors('port');
            }
          }}
          errors={[{ port: errors?.port }]}
        >
          <Controller
            render={({ field }) => (
              <div className="flex flex-col items-start justify-between">
                <div className="space-y-2">
                  <Label htmlFor="port">Port Number</Label>
                  <Input type="number" {...field} value={field.value || ''} />
                </div>
                <div className="mt-1 flex flex-row">
                  {field.value && (
                    <Text color="muted" size="xs" className="normal-case font-normal mt-1">
                      Exposing port {field.value}. The customer selected port will be available via
                      OPTRA_SKILL_WEB_PORT.
                    </Text>
                  )}
                </div>
              </div>
            )}
            name="port"
            control={control}
            disabled={loading}
            defaultValue={_isFinite(parseInt(defaultValues?.port)) || ''}
            rules={{
              validate: hasExposedUIPort ? portIsInRange : {},
            }}
          />
        </FeatureToggle>
      </Feature>

      <FeatureToggle
        icon="MonitorPlay"
        title="HDMI"
        description="Allow the skill to utilize the device's HDMI port to play video."
        readOnly={loading}
        {...register('devices.hdmi')}
      />

      <FeatureToggle
        icon="IdentificationCard"
        title="Hostname"
        description="Set a Hostname for the skill."
        readOnly={loading}
        checked={hasHostName}
        expanded={hasHostName}
        onChange={() => {
          setHasHostName(hasHostName => !hasHostName);
          if (hasHostName) {
            setValue('hostName', '');
            clearErrors('hostName');
          }
        }}
      >
        <Controller
          render={({ field }) => (
            <div className="flex items-end justify-between">
              <div className="space-y-2">
                <Label htmlFor="hostName">Hostname</Label>
                <Input type="text" {...field} value={field.value || ''} />
              </div>
            </div>
          )}
          name="hostName"
          control={control}
          disabled={loading}
          defaultValue={defaultValues?.hostName}
        />
      </FeatureToggle>

      {hostNetworkingEnabled && (
        <FeatureToggle
          icon="ShareNetwork"
          title="Host Networking"
          description="Set the skill to use Host Networking"
          readOnly={loading}
          beta
          {...register('hostNetworking')}
        />
      )}

      <FeatureToggle
        icon="Detective"
        title="Network Endpoint Alias"
        description="Set an Alias for the skill on the internal network.  "
        readOnly={loading}
        checked={hasEndpointAlias}
        expanded={true}
        onChange={() => {
          setHasEndpointAlias(hasEndpointAlias => !hasEndpointAlias);
          if (hasEndpointAlias) {
            setValue('endpointAliases', []);
            clearErrors('endpointAliases');
          }
        }}
      >
        {hasEndpointAlias && (
          <MultiInput name="endpointAliases" fields={{ alias: { label: 'Alias' } }} form={form} />
        )}
      </FeatureToggle>

      <FeatureToggle
        icon="LineSegment"
        title="Port Bindings"
        description="Allow the skill to bind one of its ports to a port on the external network."
        readOnly={loading}
        checked={hasPortBindings}
        expanded={true}
        onChange={() => {
          setHasPortBindings(hasPortBindings => !hasPortBindings);
          if (hasPortBindings) {
            setValue('portBindings', []);
            clearErrors('portBindings');
          }
        }}
        errors={errors?.portBindings}
      >
        {hasPortBindings && (
          <MultiInput
            name="portBindings"
            fields={{
              containerPort: {
                label: 'Container Port',
                type: 'number',
                validate: {
                  containerPortIsInRange: portIsInRange,
                },
                options: {
                  required: true,
                },
              },
              protocol: {
                label: 'Protocol',
                type: 'enum',
                enumValues: ['tcp', 'udp'],
                options: {
                  required: true,
                },
              },
              hostPort: {
                label: 'Host Port',
                type: 'number',
                validate: {
                  hostPortIsInRange: portIsInRange,
                },
                options: {
                  required: true,
                },
              },
            }}
            form={form}
          />
        )}
      </FeatureToggle>

      <FeatureToggle
        icon="FolderSimpleDotted"
        title="Tmpfs"
        description="Allows the skill to create temporary file systems."
        checked={hasTmpfs}
        readOnly={loading}
        expanded={true}
        onChange={() => {
          setHasTmpfs(hasTmpfs => !hasTmpfs);
          if (hasTmpfs) {
            setValue('tmpfs', []);
            clearErrors('tmpfs');
          }
        }}
        errors={errors?.tmpfs}
      >
        {hasTmpfs && (
          <MultiInput
            name="tmpfs"
            fields={{
              containerDevicePath: {
                label: 'Container Device Path',
                validate: {
                  pathIsDirectory: value => {
                    const p = new RegExp('^/|(/[w-]+)+$');
                    return p.test(value) || 'Device Path must be in the shape of /directory';
                  },
                },
                options: {
                  required: true,
                },
              },
              sizeBytes: {
                label: 'Size (blank for unlimited)',
                type: 'number',
                placeholder: 'bytes',
                options: {
                  required: false,
                },
              },
            }}
            form={form}
          />
        )}
      </FeatureToggle>

      <FeatureToggle
        icon="HardDrives"
        title="SHM Size"
        description="Allows the skill to set its Linux Shared Memory size."
        checked={hasShmSize}
        expanded={hasShmSize}
        readOnly={loading}
        onChange={() => {
          setHasShmSize(hasShmSize => !hasShmSize);
          if (hasShmSize) {
            setValue('shmSize', '');
            clearErrors('shmSize');
          }
        }}
        errors={errors?.shmSize?.type === 'shmSizeIsNumeric' && errors?.shmSize}
      >
        <Controller
          render={({ field }) => (
            <div className="flex items-end justify-between my-4">
              <div className="space-y-2">
                <Label htmlFor="shmSize">Size (in bytes)</Label>
                <Input
                  type="number"
                  {...field}
                  onChange={evt => field.onChange(parseFloat(evt.target.value))}
                  value={field.value || ''}
                />
              </div>
            </div>
          )}
          name="shmSize"
          control={control}
          disabled={loading}
          defaultValue={defaultValues?.shmSize}
          rules={{
            required: hasShmSize,
          }}
        />
      </FeatureToggle>
    </div>
  );
}
