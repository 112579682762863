import { isEmpty, template, union, unionBy, uniq } from 'lodash';
import { z } from 'zod';

function getBinding(str) {
  // NOTE: Permissions are always 3rd and are the only optional field
  const [binding = '', path = '', permissions = 'rw'] = str?.split?.(':') || [];
  return {
    binding,
    path,
    permissions,
    normalized: `${binding}:${path}:${permissions}`,
  };
}

/**
 * Known keys as defined in the spec [here]{@link https://github.com/Azure/iotedge/tree/main/edge-agent/src/Microsoft.Azure.Devices.Edge.Agent.Docker/models}
 */

export const createOptionsSchema = z
  .object({
    Hostname: z.string().nullish(),
    Domainname: z.string().nullish(),
    User: z.string().nullish(),
    AttachStdin: z.boolean().nullish(),
    AttachStdout: z.boolean().nullish(),
    AttachStderr: z.boolean().nullish(),
    Tty: z.boolean().nullish(),
    OpenStdin: z.boolean().nullish(),
    StdinOnce: z.boolean().nullish(),
    Env: z.array(z.string()).nullish(),
    Cmd: z.array(z.string()).nullish(),
    Entrypoint: z.string().nullish(),
    Image: z.string().nullish(),
    Labels: z.record(z.string()).nullish(),
    Volumes: z.record(z.object({}).passthrough()).nullish(),
    WorkingDir: z.string().nullish(),
    NetworkDisabled: z.boolean().nullish(),
    MacAddress: z.string().nullish(),
    ExposedPorts: z.record(z.object({}).passthrough()).nullish(),
    StopSignal: z.string().nullish(),
    StopTimeout: z.number().int().positive().nullish(),
    HostConfig: z
      .object({
        AutoRemove: z.boolean().nullish(),
        Binds: z.array(z.string()).nullish(),
        BlkioDeviceReadBps: z
          .array(
            z
              .object({
                Path: z.string(),
                Rate: z.number(),
              })
              .strict(),
          )
          .nullish(),
        BlkioDeviceReadIOps: z
          .array(
            z
              .object({
                Path: z.string(),
                Rate: z.number(),
              })
              .strict(),
          )
          .nullish(),
        BlkioDeviceWriteBps: z
          .array(
            z
              .object({
                Path: z.string(),
                Rate: z.number(),
              })
              .strict(),
          )
          .nullish(),
        BlkioDeviceWriteIOps: z
          .array(
            z
              .object({
                Path: z.string(),
                Rate: z.number(),
              })
              .strict(),
          )
          .nullish(),
        BlkioWeight: z.number().int().min(0).max(1000).nullish(),
        BlkioWeightDevice: z
          .array(
            z
              .object({
                Path: z.string(),
                Weight: z.number().int().min(0),
              })
              .strict(),
          )
          .nullish(),
        CpuCount: z.number().int().nullish(),
        CpuPercent: z.number().int().nullish(),
        CpuPeriod: z.number().int().nullish(),
        CpuQuota: z.number().int().nullish(),
        CpuRealtimePeriod: z.number().int().nullish(),
        CpuRealtimeRuntime: z.number().int().nullish(),
        CpuShares: z.number().int().nullish(),
        CapAdd: z.array(z.string()).nullish(),
        CapDrop: z.array(z.string()).nullish(),
        Cgroup: z.string().nullish(),
        CgroupParent: z.string().nullish(),
        ConsoleSize: z.tuple([z.number(), z.number()]).nullish(),
        ContainerIDFile: z.string().nullish(),
        CpusetCpus: z.string().nullish(),
        CpusetMems: z.string().nullish(),
        Dns: z.array(z.string()).nullish(),
        DnsOptions: z.array(z.string()).nullish(),
        DnsSearch: z.array(z.string()).nullish(),
        DiskQuota: z.number().int().nullish(),
        ExtraHosts: z.array(z.string()).nullish(),
        GroupAdd: z.array(z.string()).nullish(),
        IOMaximumIOps: z.number().int().nullish(),
        IOMaximumBandwidth: z.number().int().nullish(),
        Init: z.boolean().nullish(),
        InitPath: z.string().nullish(),
        IpcMode: z
          .string()
          .regex(
            /(none|private|shareable|host|^container:.*)/,
            'Must be one of none, private, shareable, host, or container:*',
          )
          .nullish(),
        Isolation: z.enum(['default', 'process', 'hyperv']).nullish(),
        KernelMemory: z.number().int().nullish(),
        Links: z.array(z.string()).nullish(),
        MaximumIOBps: z.number().int().nullish(),
        MaximumIOps: z.number().int().nullish(),
        Memory: z.number().int().nullish(),
        MemoryReservation: z.number().int().nullish(),
        MemorySwap: z.number().int().nullish(),
        MemorySwappiness: z.number().int().min(0).max(100).nullish(),
        NanoCpus: z.number().int().nullish(),
        NetworkMode: z.string().nullish(),
        OomKillDisable: z.boolean().nullish(),
        OomScoreAdj: z.number().int().nullish(),
        PidMode: z
          .string()
          .regex(/(host|$container:.*)/, 'Must be one of host or container:*')
          .nullish(),
        PidsLimit: z.number().int().nullish(),
        Privileged: z.boolean().nullish(),
        PublishAllPorts: z.boolean().nullish(),
        ReadonlyRootfs: z.boolean().nullish(),
        Runtime: z.string().nullish(),
        SecurityOpt: z.array(z.string()).nullish(),
        ShmSize: z.number().int().min(0).nullish(),
        StorageOpt: z.object({}).passthrough().nullish(),
        Sysctls: z.object({}).passthrough().nullish(),
        Tmpfs: z.object({}).passthrough().nullish(),
        UTSMode: z.string().nullish(),
        Ulimits: z
          .array(
            z
              .object({
                Name: z.string(),
                Soft: z.number().int(),
                Hard: z.number().int(),
              })
              .strict(),
          )
          .nullish(),
        UsernsMode: z.string().nullish(),
        VolumeDriver: z.string().nullish(),
        VolumesFrom: z.array(z.string()).nullish(),
        // OtherProperties // ignored
        PortBindings: z
          .record(
            z.array(
              z
                .object({
                  HostIp: z.string().nullish(),
                  HostPort: z.string(),
                  // OtherProperties // ignored
                })
                .passthrough(),
            ),
          )
          .nullish(),
        Devices: z
          .array(
            z
              .object({
                PathOnHost: z.string().nullish(),
                PathInContainer: z.string().nullish(),
                CgroupPermissions: z.string().nullish(),
                // OtherProperties // ignored
              })
              .passthrough(),
          )
          .nullish(),
        Mounts: z
          .array(
            z
              .object({
                ReadOnly: z.boolean().nullish(),
                Source: z.string().nullish(),
                Target: z.string().nullish(),
                Type: z.string().nullish(),
                // OtherProperties // ignored
              })
              .passthrough(),
          )
          .nullish(),
        LogConfig: z
          .object({
            Type: z.enum([
              'json-file',
              'syslog',
              'journald',
              'gelf',
              'fluentd',
              'awslogs',
              'splunk',
              'etwlogs',
              'none',
            ]),
            Config: z.object({}).passthrough().nullish(),
            // OtherProperties // ignored
          })
          .passthrough()
          .nullish(),
        RestartPolicy: z
          .object({
            Name: z.enum(['', 'always', 'unless-stopped', 'on-failure']),
            MaximumRetryCount: z.number().int(),
            // OtherProperties // ignored
          })
          .passthrough()
          .nullish(),
      })
      .nullish(),
    NetworkingConfig: z
      .object({
        EndpointsConfig: z.record(
          z
            .object({
              IPAMConfig: z
                .object({
                  IPv4Address: z.string().nullish(),
                  IPv6Address: z.string().nullish(),
                  LinkLocalIPs: z.array(z.string()).nullish(),
                })
                .strict()
                .nullish(),
              Links: z.array(z.string()).nullish(),
              Aliases: z.array(z.string()).nullish(),
              NetworkID: z.string().nullish(),
              EndpointID: z.string().nullish(),
              Gateway: z.string().nullish(),
              IPAddress: z.string().nullish(),
              IPPrefixLen: z.number().int().nullish(),
              IPv6Gateway: z.string().nullish(),
              GlobalIPv6Address: z.string().nullish(),
              GlobalIPv6PrefixLen: z.number().int().nullish(),
              MacAddress: z.string().nullish(),
              DriverOpts: z.object({}).passthrough().nullish(),
              // OtherProperties // ignored
            })
            .passthrough(),
        ),
        // OtherProperties // ignored
      })
      .passthrough()
      .nullish(),
  })
  .strict();

export default async function calculateRawConfig({ form = {} }) {
  const { hostName, endpointAliases, portBindings, tmpfs, shmSize, createOptions = '{}' } = form;
  try {
    if (!isEmpty(createOptions)) {
      JSON.parse(
        template(createOptions)({
          exposedUIPort: 9000, // placeholder port to test for template errors! Do not wire up!
          hostUIPort: 9000, // placeholder port to test for template errors! Do not wire up!
          id: '1234', // placeholder id to test for template errors! Do not wire up!
        }),
      );
    }
  } catch (err) {
    return {
      data: {},
      errors: [err.message],
    };
  }

  const parsed = isEmpty(createOptions) ? {} : JSON.parse(createOptions);
  const customHostConfig = parsed.HostConfig || {};

  const allBinds = union(
    (form.storage || []).map(({ key, value }) => {
      const binding = getBinding(`${key}:${value}`);
      return binding.normalized;
    }),
    form.devices?.sound ? ['/etc/asound.conf:/etc/asound.conf'] : [],
    form.devices?.hdmi ? ['/tmp/.X11-unix:/tmp/.X11-unix'] : [],
    form.removableMedia ? ['/run/removable_media:/media:shared'] : [],
    form.devices?.cameras ? ['/dev:/dev:ro'] : [],
    // customOptions are included last, because the algorithm below keeps the first instance, and drops subsequent duplicates
    customHostConfig.Binds || [],
  );

  // Filter duplicates in Binds, but only based on name and path (ignore permissions)
  const Binds = allBinds.reduce((binds, bind) => {
    const binding = getBinding(bind);

    // If the bind name and path match an existing entry, replace it
    const hasDuplicates = binds.some(existingBind => {
      const otherBinding = getBinding(existingBind);
      return otherBinding.binding === binding.binding && otherBinding.path === binding.path;
    });
    if (hasDuplicates) return binds;

    return [...binds, binding.normalized];
  }, []);

  const skillVersionPort = form.port;

  const calculatedCreateOptions = {
    ...(parsed || {}),
    HostConfig: {
      ...(customHostConfig || {}),
      Binds,
      Devices: unionBy(
        form.devices?.sound
          ? [
              {
                CgroupPermissions: 'rwm',
                PathInContainer: '/dev/snd',
                PathOnHost: '/dev/snd',
              },
            ]
          : [],
        customHostConfig.Devices || [],
        d => `${d.PathInContainer}${d.PathOnHost}`,
      ),
      ...(form.devices?.cameras
        ? {
            DeviceCgroupRules: uniq([
              ...(customHostConfig?.DeviceCgroupRules || []),
              'c 81:* rmw',
              'a 189:* rwm',
            ]),
          }
        : {}),
      PortBindings: {
        ...(customHostConfig.PortBindings || {}),
        ...(portBindings?.length > 0
          ? portBindings.reduce(
              (bindings, binding) => ({
                ...bindings,
                [`${binding.containerPort}/${binding.protocol}`]: [
                  { HostPort: `${binding.hostPort}` },
                ],
              }),
              {},
            )
          : {}),
        ...(!!skillVersionPort
          ? {
              [`${skillVersionPort}/tcp`]: [{ HostPort: '<User Selected Port>' }], // eslint-disable-line no-template-curly-in-string
            }
          : {}),
      },
      Mounts: unionBy(
        (tmpfs || []).map(t => ({
          Type: 'tmpfs',
          Target: t.containerDevicePath,
          ...(t.sizeBytes ? { TmpfsOptions: { SizeBytes: t.sizeBytes } } : {}),
        })),
        customHostConfig.Mounts || [],
        m => `${m.Target}${m.Source}${m.Type}`,
      ),
      ...(shmSize ? { ShmSize: shmSize } : {}),
      ...(form.hostNetworking ? { NetworkMode: 'host' } : {}),
    }, // End HostConfig
    ...(!isEmpty(hostName) ? { Hostname: hostName } : {}),
    ...(endpointAliases?.length > 0 || form.hostNetworking
      ? {
          NetworkingConfig: {
            EndpointsConfig: {
              ...(parsed?.NetworkingConfig?.EndpointsConfig || {}),
              ...(form.hostNetworking ? { host: {} } : {}),
              ...(endpointAliases?.length > 0
                ? {
                    'azure-iot-edge': {
                      Aliases: union(
                        endpointAliases.map(a => a?.alias),
                        parsed?.NetworkingConfig?.EndpointsConfig?.['azure-iot-edge']?.Aliases ||
                          [],
                      ),
                    },
                  }
                : {}),
            },
          },
        }
      : {}),
  };

  return createOptionsSchema
    .safeParseAsync(calculatedCreateOptions)
    .then(({ error }) => ({
      data: calculatedCreateOptions,
      errors: error?.errors || [],
    }))
    .catch(err => ({
      data: calculatedCreateOptions,
      errors: [err?.message],
    }));
}
