import { Icon, Tooltip, UnreadDot } from '@optra/kit';

import SkillIcon from 'components/skill-icon';
import { useHasRoles } from 'hooks';

import WorkflowListItem from './workflow-list-item';

export function disabledReasonTooltipText(disabledReasons = []) {
  const reasons = {
    SYNTHESIS_STATE: 'This skill cannot be deployed because it is still being created',
  };
  return reasons[disabledReasons?.[0]] || 'This skill cannot be deployed yet.';
}

export default function WorkflowSkillListItem(props) {
  const {
    deleted = false,
    dirty = false,
    disabled: _disabled = false,
    disabledReasons,
    deleting = false,
    icon,
    iconUrl,
    color,
    id,
    name,
    developerName,
    updateAvailable = false,
    workflowId,
  } = props;
  const [canEdit] = useHasRoles(['admin', 'deviceTechnician', 'workflowDeployer']);
  const disabled = !!(_disabled || !canEdit);

  const to = `/workflows/${workflowId}/workflow-skills/${id}/edit`;

  let variant = 'default';
  if (dirty) variant = 'dirty';
  if (deleted) variant = 'deleted';
  if (disabled || deleting) variant = 'disabled';

  let statusIcon = null;
  let tooltipLabel = null;

  if (updateAvailable) {
    statusIcon = <UnreadDot />;
    tooltipLabel = 'Updated version available.';
  }
  if (dirty) {
    statusIcon = <Icon name="ArrowsClockwise" size="xs" />;
    tooltipLabel = 'This skill has un-synced changes.';
  }
  if (deleted) {
    statusIcon = <Icon name="Trash" size="xs" />;
    tooltipLabel = 'This skill is deleted pending sync.';
  }
  if (disabled || deleting) {
    statusIcon = <Icon name="Prohibit" size="xs" weight="line" />;
    tooltipLabel = disabledReasonTooltipText(disabledReasons);
  }

  return (
    <WorkflowListItem
      variant={variant}
      name={name}
      details={[developerName]}
      disabled={disabled || deleting}
      disabledAllowInteraction={!deleting}
      to={to}
      components={{
        before: <SkillIcon size="sm" color={color} icon={icon} iconUrl={iconUrl} />,
        after: statusIcon && (
          <Tooltip label={tooltipLabel}>
            <span className="leading-none">{statusIcon}</span>
          </Tooltip>
        ),
      }}
    />
  );
}
