import cx from 'classnames';
import { isFunction, isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Table, DetailList, VersionLabel, Text } from '@optra/kit';

import { useFeature } from 'components/feature';
import SkillIcon from 'components/skill-icon';
import SkillStatus from 'components/skill-status';
import { useHasRoles } from 'hooks';

function SkillListItem(props) {
  const {
    id,
    color: _color,
    icon: _icon,
    iconUrl,
    name,
    developerProfile,
    latestVersion,
    __typename,
    manualUrl,
    details = [],
    caret = true,
    disabled,
    onClick,
    rounded,
    isSkillBuilder,
    isSkillBuilderV2,
    ...rest
  } = props;
  const [canEdit] = useHasRoles(['admin', 'workflowDeployer']);
  const navigate = useNavigate();
  const skillStatusEnabled = useFeature('skillStatus');

  const handleClick = () => {
    if (isFunction(onClick)) {
      onClick();
      return;
    }
    if (__typename === 'MarketplaceSkill') {
      const tab = isEmpty(manualUrl) ? '' : '?tab=manual';
      navigate(`/skills/${id}${tab}`);
    } else if (canEdit) {
      if (isSkillBuilderV2) {
        navigate(`/skills/${id}/versions/${latestVersion.id}/pipeline`);
      } else {
        navigate(`/skills/${id}/edit`);
      }
    } else {
      return false;
    }
  };

  // NOTE: Skills do not have version updates, we established with Robbie previously that access to a skill provides access to all versions of that skill and updates occur only at the device level
  // const currentVersion = skill?.versions?.data?.[0]?.version;
  const latestVersionNumber = latestVersion?.version;
  const icon = isSkillBuilder ? 'model' : _icon;
  const color = isSkillBuilder ? '#0d9488' : _color;

  return (
    <Table.Row
      caret={canEdit && !disabled && caret}
      hover={canEdit}
      disabled={disabled}
      onClick={canEdit ? handleClick : null}
      className={cx(!canEdit && 'pointer-events-none')}
      {...rest}
    >
      <Table.TD collapsed>
        <SkillIcon size="sm" color={color} icon={icon} iconUrl={iconUrl} circle={rounded} />
      </Table.TD>
      <Table.TD>
        <Text size="sm">{name}</Text>
        <DetailList
          details={[
            developerProfile && {
              title: 'Developer',
              component: developerProfile.name,
            },
            latestVersionNumber && {
              title: 'Version',
              component: <VersionLabel version={latestVersionNumber} />,
            },
            isSkillBuilderV2 &&
              skillStatusEnabled && {
                component: <SkillStatus status={latestVersion.synthesisState} />,
              },
            ...details,
          ]}
        />
      </Table.TD>
    </Table.Row>
  );
}

export default function SkillList({ skills = [] }) {
  return (
    <Table>
      <Table.Body>
        {skills.map(skill => {
          if (!skill) {
            return null;
          }
          return <SkillListItem key={skill.id} rounded={skill.isSkillBuilder} {...skill} />;
        })}
      </Table.Body>
    </Table>
  );
}
