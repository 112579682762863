import { Login } from 'react-auth0rize';
import { Route, Routes, Outlet } from 'react-router-dom';

import AdminLayout from 'components/admin-layout';
import Modal from 'components/modal';
import ProfileLayout from 'components/profile-layout';
import { supportUrl } from 'config/constants';
import useVersionCheck from 'hooks/use-version-check';
import AddWorkflowAction from 'modals/add-workflow-action';
import AddWorkflowSkill from 'modals/add-workflow-skill';
import AdminCreateFirmware from 'modals/admin-create-firmware';
import AdminCreateInternalSkillVersion from 'modals/admin-create-internal-skill-version';
import AdminCreateOrganization from 'modals/admin-create-organization';
import AdminCreateServiceAccount from 'modals/admin-create-service-account';
import AdminCreateSuperUser from 'modals/admin-create-super-user';
import AdminEditInternalSkill from 'modals/admin-edit-internal-skill';
import AdminEditInternalSkillVersion from 'modals/admin-edit-internal-skill-version';
import AdminEditOrganization from 'modals/admin-edit-organization';
import AdminEditServiceAccount from 'modals/admin-edit-service-account';
import AdminEditUser from 'modals/admin-edit-user';
import AdminMarketplaceCreateListing from 'modals/admin-marketplace-create-listing';
import AdminMarketplaceEditListing from 'modals/admin-marketplace-edit-listing';
import AdminPreclaimDevicesUpload from 'modals/admin-preclaimed-devices-upload';
import AdminPurchaseOrderCreate from 'modals/admin-purchase-order-create';
import AdminPurchaseOrderUpdate from 'modals/admin-purchase-order-update';
import BulkCreateDevices from 'modals/bulk-create-devices';
import BulkEditWorkflow from 'modals/bulk-edit-workflow';
import ChooseGroupDevices from 'modals/choose-group-devices';
import ChooseWorkflowDevices from 'modals/choose-workflow-devices';
import CopySkill from 'modals/copy-skill';
import CopyWorkflowItems from 'modals/copy-workflow-items';
import CreateDevice from 'modals/create-device';
import CreateDeviceGroup from 'modals/create-device-group';
import CreateOrganization from 'modals/create-organization';
import CreateSkillDockerCompose from 'modals/create-skill-docker-compose';
import CreateSkillDockerComposeVersion from 'modals/create-skill-docker-compose-version';
import CreateSkillDockerImage from 'modals/create-skill-docker-image';
import CreateSkillDockerImageVersion from 'modals/create-skill-docker-image-version';
import CreateSkillOptions from 'modals/create-skill-options';
import CreateSkillTemplates from 'modals/create-skill-templates';
import CreateUser from 'modals/create-user';
import CreateWorkflow from 'modals/create-workflow';
import CreateWorkflowSkill from 'modals/create-workflow-skill';
import DeviceDetail from 'modals/device-detail';
import DuplicateWorkflow from 'modals/duplicate-workflow';
import EditDeviceGroup from 'modals/edit-device-group';
import EditOrganization from 'modals/edit-organization';
import EditPublishedSkill from 'modals/edit-published-skill';
import EditSkill from 'modals/edit-skill';
import EditSkillVersion from 'modals/edit-skill-version';
import EditUser from 'modals/edit-user';
import EditWorkflow from 'modals/edit-workflow';
import EditWorkflowAction from 'modals/edit-workflow-action';
import EditWorkflowSkill from 'modals/edit-workflow-skill';
import ManageLocations from 'modals/manage-locations';
import MarketplaceDetail from 'modals/marketplace-detail';
import MarketplaceDetailGallery from 'modals/marketplace-detail-gallery';
import MarketplaceSubscribe from 'modals/marketplace-subscribe';
import CreateModel from 'modals/model/create-model';
import EditModel from 'modals/model/edit-model';
import PublishSkill from 'modals/publish-skill';
import SkillPipelineBuilder from 'modals/skill-pipeline-builder';
import UserAccessSecrets from 'modals/user-access-secrets';
import WorkflowSyncProgress from 'modals/workflow-sync-progress';
import AdminFeatures from 'pages/admin-features';
import AdminFirmware from 'pages/admin-firmware';
import AdminInternalSkills from 'pages/admin-internal-skills';
import AdminMarketplace from 'pages/admin-marketplace';
import AdminOrganizations from 'pages/admin-organizations';
import AdminPreclaimedDevices from 'pages/admin-preclaimed-devices';
import AdminProvisioning from 'pages/admin-provisioning';
import AdminResellerCodes from 'pages/admin-reseller-codes';
import AdminResellerCodeGiftCardDetail from 'pages/admin-reseller-codes-gift-card-detail';
import AdminResellerCodePurchaseOrderDetail from 'pages/admin-reseller-codes-purchase-order-detail';
import AdminServiceAccounts from 'pages/admin-service-accounts';
import AdminSkillBuilderBetaNodeDetail from 'pages/admin-skill-builder-beta-node-detail';
import AdminSkillBuilderBetaNodeNew from 'pages/admin-skill-builder-beta-node-new';
import AdminSkillBuilderBetaNodes from 'pages/admin-skill-builder-beta-nodes';
import AdminSkillBuilderConfig from 'pages/admin-skill-builder-config';
import AdminSkillBuilderModels from 'pages/admin-skill-builder-models';
import AdminSkillBuilderRecipeDetail from 'pages/admin-skill-builder-recipe-detail';
import AdminSkillBuilderRecipeNew from 'pages/admin-skill-builder-recipe-new';
import AdminSkillBuilderRecipes from 'pages/admin-skill-builder-recipes';
import AdminSkillBuilderTemplateDetail from 'pages/admin-skill-builder-template-detail';
import AdminSkillBuilderTemplateNew from 'pages/admin-skill-builder-template-new';
import AdminSkillBuilderTemplates from 'pages/admin-skill-builder-templates';
import AdminSuperUsers from 'pages/admin-super-users';
import AdminUsage from 'pages/admin-usage';
import AdminUsers from 'pages/admin-users';
import AuthCallback from 'pages/auth-callback';
import AuthorizeAction from 'pages/authorize-action';
import Devices from 'pages/devices';
import Logout from 'pages/logout';
import Marketplace from 'pages/marketplace';
import Models from 'pages/models';
import Privacy from 'pages/privacy';
import Profile from 'pages/profile';
import Skills from 'pages/skills';
import SoftwareAgreement from 'pages/software-agreement';
import TermsAndConditions from 'pages/terms-and-conditions';
import TermsOfUse from 'pages/terms-of-use';
import Users from 'pages/users';
import Workflows from 'pages/workflows';
import Workspaces from 'pages/workspaces';
import ExternalRedirect from 'router/external-redirect';
import IntendedRouteTracker from 'router/intended-route-tracker';
import NoMatch from 'router/no-match';
import ProtectedRoute from 'router/protected-route';

import PinnedRoute from './pinnedRoute';

export default function AppRoutes() {
  useVersionCheck();
  return (
    <>
      <IntendedRouteTracker ignore={['/login', '/logout', '/authorize', '/eula']} />

      <Routes>
        <Route path="/" element={<PinnedRoute />} />
        {/* Workflows Tab */}
        <Route
          path="workflows"
          element={
            <ProtectedRoute
              authenticate
              element={
                <>
                  <Workflows />
                  <Outlet />
                </>
              }
            />
          }
        >
          <Route
            path=":workflowId/choose-devices"
            element={
              <Modal>
                <ChooseWorkflowDevices />
              </Modal>
            }
          />
          <Route
            path=":workflowId/groups/create"
            element={
              <Modal>
                <CreateDeviceGroup />
              </Modal>
            }
          />
          <Route
            path=":workflowId/groups/:groupId/edit"
            element={
              <Modal>
                <EditDeviceGroup />
              </Modal>
            }
          />
          <Route
            path=":workflowId/groups/:groupId"
            element={
              <Modal>
                <ChooseGroupDevices />
              </Modal>
            }
          />
          {/* Must be last to handle children tab paths */}
          <Route
            path=":workflowId/workflow-devices/create/*"
            element={
              <Modal>
                <CreateDevice />
              </Modal>
            }
          />
          <Route
            path=":workflowId/workflow-devices/:deviceId/*"
            element={
              <Modal>
                <DeviceDetail />
              </Modal>
            }
          />
          {/* Workflow Skills */}
          <Route
            path=":workflowId/workflow-skills/add"
            element={
              <Modal>
                <AddWorkflowSkill />
              </Modal>
            }
          />
          <Route
            path=":workflowId/workflow-skills/create/:skillId"
            element={
              <Modal>
                <CreateWorkflowSkill />
              </Modal>
            }
          />
          <Route
            path=":workflowId/skills/create-options"
            element={
              <Modal>
                <CreateSkillOptions />
              </Modal>
            }
          />
          {/* TODO: None of this is actually used */}
          <Route
            path=":workflowId/skills/create-templates"
            element={
              <Modal>
                <CreateSkillTemplates />
              </Modal>
            }
          />
          <Route
            path=":workflowId/skills/create-docker-image"
            element={
              <Modal>
                <CreateSkillDockerImage />
              </Modal>
            }
          />
          <Route
            path=":workflowId/workflow-skills/:workflowSkillId/edit"
            element={
              <Modal>
                <EditWorkflowSkill />
              </Modal>
            }
          />
          {/* Actions */}
          <Route
            path=":workflowId/workflow-actions/:workflowActionId/edit/*"
            element={
              <Modal>
                <EditWorkflowAction />
              </Modal>
            }
          />
          <Route
            path=":workflowId/workflow-actions/add"
            element={
              <Modal>
                <AddWorkflowAction />
              </Modal>
            }
          />
          {/* Workflow */}
          <Route
            path=":workflowId/copy-workflow-items"
            element={
              <Modal>
                <CopyWorkflowItems />
              </Modal>
            }
          />
          <Route
            path=":workflowId/duplicate"
            element={
              <Modal>
                <DuplicateWorkflow />
              </Modal>
            }
          />
          <Route
            path=":workflowId/edit"
            element={
              <Modal>
                <EditWorkflow />
              </Modal>
            }
          />
          <Route
            path=":workflowId/bulk-edit"
            element={
              <Modal>
                <BulkEditWorkflow />
              </Modal>
            }
          />
          <Route
            path=":workflowId/deployments/latest/*"
            element={
              <Modal>
                <WorkflowSyncProgress />
              </Modal>
            }
          />
          <Route
            path="create"
            element={
              <Modal>
                <CreateWorkflow />
              </Modal>
            }
          />
        </Route>

        {/* Devices Tab */}
        <Route
          path="devices"
          element={
            <ProtectedRoute
              authenticate
              element={
                <>
                  <Devices />
                  <Outlet />
                </>
              }
            />
          }
        >
          <Route
            path="create-bulk"
            element={
              <Modal>
                <BulkCreateDevices />
              </Modal>
            }
          />
          {/* Must be last to handle children tab paths */}
          <Route
            path="create/*"
            element={
              <Modal>
                <CreateDevice />
              </Modal>
            }
          />
          <Route
            path=":deviceId/*"
            element={
              <Modal>
                <DeviceDetail />
              </Modal>
            }
          />
          <Route
            path="manage-locations/*"
            element={
              <Modal>
                <ManageLocations />
              </Modal>
            }
          />
        </Route>

        {/* Skills Tab */}
        <Route path="skills" element={<ProtectedRoute authenticate />}>
          <Route
            element={
              <>
                <Skills />
                <Outlet />
              </>
            }
          >
            <Route index element={null} />
            <Route
              path="create/options"
              element={
                <Modal>
                  <CreateSkillOptions />
                </Modal>
              }
            />
            <Route
              path="create/templates"
              element={
                <Modal>
                  <CreateSkillTemplates />
                </Modal>
              }
            />
            <Route
              path="create/docker-image"
              element={
                <Modal>
                  <CreateSkillDockerImage />
                </Modal>
              }
            />
            <Route
              path="create/docker-compose"
              element={
                <Modal>
                  <CreateSkillDockerCompose />
                </Modal>
              }
            />
            <Route
              path="create/builder/*"
              element={
                <Modal cover>
                  <SkillPipelineBuilder />
                </Modal>
              }
            />
            <Route
              path=":skillId"
              element={
                <Modal>
                  <Outlet />
                </Modal>
              }
            >
              <Route
                path="edit"
                element={
                  <>
                    <EditSkill />
                    <Outlet />
                  </>
                }
              >
                <Route index element={null} />
                <Route path="publish/*" element={<PublishSkill />} />
                <Route path="listing/*" element={<EditPublishedSkill />} />
                <Route
                  path="copy"
                  element={
                    <ProtectedRoute authenticate requiresGlobalAdmin element={<CopySkill />} />
                  }
                />
              </Route>
              <Route path="versions/add" element={<CreateSkillDockerImageVersion />} />
              <Route path="versions/add_compose" element={<CreateSkillDockerComposeVersion />} />
              <Route path="versions/:skillVersionId" element={<EditSkillVersion />} />

              <Route
                path="versions/:skillVersionId/pipeline/*"
                element={
                  <Modal cover closePath="/skills">
                    <SkillPipelineBuilder />
                  </Modal>
                }
              />
            </Route>

            <Route
              path=":marketplaceSkillId/subscribe"
              element={
                <Modal>
                  <MarketplaceSubscribe />
                </Modal>
              }
            />
            <Route
              path=":marketplaceSkillId"
              element={
                <Modal>
                  <MarketplaceDetail />
                  <Outlet />
                </Modal>
              }
            >
              <Route index element={null} />
              <Route path="gallery" element={<MarketplaceDetailGallery />} />
            </Route>
          </Route>

          <Route
            path="models"
            element={
              <>
                <Models />
                <Outlet />
              </>
            }
          >
            <Route index element={null} />
            <Route
              path="create"
              element={
                <Modal>
                  <CreateModel />
                </Modal>
              }
            />
            <Route
              path=":modelId"
              element={
                <Modal>
                  <Outlet />
                </Modal>
              }
            >
              <Route index element={null} />
              <Route
                path="edit"
                element={
                  <>
                    <EditModel />
                    <Outlet />
                  </>
                }
              />
            </Route>
          </Route>

          <Route
            path="marketplace"
            element={
              <>
                <Marketplace />
                <Outlet />
              </>
            }
          >
            <Route index element={null} />
            <Route
              path=":marketplaceSkillId/subscribe"
              element={
                <Modal>
                  <MarketplaceSubscribe />
                </Modal>
              }
            />
            <Route
              path=":marketplaceSkillId"
              element={
                <Modal>
                  <MarketplaceDetail />
                  <Outlet />
                </Modal>
              }
            >
              <Route index element={null} />
              <Route path="gallery" element={<MarketplaceDetailGallery />} />
            </Route>
          </Route>
        </Route>

        {/* Charts Tab */}
        <Route
          path="charts/*"
          element={
            <ProtectedRoute
              authenticate
              orgMemberRoles={['admin', 'chartEditor', 'chartViewerOnly']}
              allowChartViewer
              element={<Workspaces />}
            />
          }
        />

        {/* Users Tab */}
        <Route path="users" element={<ProtectedRoute authenticate orgMemberRoles={['admin']} />}>
          <Route
            element={
              <>
                <Users />
                <Outlet />
              </>
            }
          >
            <Route index element={null} />
            <Route
              path="create"
              element={
                <Modal>
                  <CreateUser />
                </Modal>
              }
            />
            <Route
              path="api-keys"
              element={
                <Modal>
                  <UserAccessSecrets />
                </Modal>
              }
            />
            <Route
              path=":userId/edit"
              element={
                <Modal>
                  <EditUser />
                </Modal>
              }
            />
          </Route>
        </Route>

        {/* Profile Tab */}
        <Route
          path="profile"
          element={<ProtectedRoute authenticate allowChartViewer element={<ProfileLayout />} />}
        >
          <Route
            element={
              <>
                <Profile />
                <Outlet />
              </>
            }
          >
            <Route index element={null} />
            <Route
              path="organizations/create"
              element={
                <Modal>
                  <CreateOrganization />
                </Modal>
              }
            />
            <Route
              path="organizations/:organizationId/edit"
              element={
                <Modal>
                  <EditOrganization />
                </Modal>
              }
            />
            <Route
              path="api-keys"
              element={
                <Modal>
                  <UserAccessSecrets />
                </Modal>
              }
            />
          </Route>
        </Route>

        {/* Super Admin */}
        <Route
          path="admin"
          element={<ProtectedRoute authenticate requiresGlobalAdmin element={<AdminLayout />} />}
        >
          <Route
            path="service-accounts"
            element={
              <>
                <AdminServiceAccounts />
                <Outlet />
              </>
            }
          >
            <Route
              path="create"
              element={
                <Modal>
                  <AdminCreateServiceAccount />
                </Modal>
              }
            />
            <Route
              path=":serviceAccountId/edit"
              element={
                <Modal>
                  <AdminEditServiceAccount />
                </Modal>
              }
            />
          </Route>
          <Route
            path="organizations"
            element={
              <>
                <AdminOrganizations />
                <Outlet />
              </>
            }
          >
            <Route
              path="create"
              element={
                <Modal>
                  <AdminCreateOrganization />
                </Modal>
              }
            />
            <Route
              path=":organizationId/edit"
              element={
                <Modal>
                  <AdminEditOrganization />
                </Modal>
              }
            />
          </Route>
          <Route
            path="super-users"
            element={
              <>
                <AdminSuperUsers />
                <Outlet />
              </>
            }
          >
            <Route
              path="create"
              element={
                <Modal>
                  <AdminCreateSuperUser />
                </Modal>
              }
            />
          </Route>
          <Route
            path="firmware"
            element={
              <>
                <AdminFirmware />
                <Outlet />
              </>
            }
          >
            <Route
              path="create"
              element={
                <Modal>
                  <AdminCreateFirmware />
                </Modal>
              }
            />
          </Route>
          <Route
            path="preclaimed-devices"
            element={
              <>
                <AdminPreclaimedDevices />
                <Outlet />
              </>
            }
          >
            <Route
              path="upload"
              element={
                <Modal>
                  <AdminPreclaimDevicesUpload />
                </Modal>
              }
            />
          </Route>
          <Route
            path="usage"
            element={
              <>
                <AdminUsage />
                <Outlet />
              </>
            }
          >
            <Route
              path=":deviceId/*"
              element={
                <Modal>
                  <DeviceDetail />
                </Modal>
              }
            />
          </Route>
          <Route
            path="marketplace"
            element={
              <>
                <AdminMarketplace />
                <Outlet />
              </>
            }
          >
            <Route
              path="listings/create"
              element={
                <Modal>
                  <AdminMarketplaceCreateListing />
                </Modal>
              }
            />
            <Route path="listings/:skillId/edit/*" element={<AdminMarketplaceEditListing />} />
          </Route>
          <Route
            path="usage-credits"
            element={
              <>
                <AdminResellerCodes />
                <Outlet />
              </>
            }
          >
            <Route
              path="create-purchase-order"
              element={
                <Modal>
                  <AdminPurchaseOrderCreate />
                </Modal>
              }
            />
          </Route>
          <Route
            path="usage-credits/purchase-order/:id"
            element={
              <>
                <AdminResellerCodePurchaseOrderDetail />
                <Outlet />
              </>
            }
          >
            <Route
              path="edit"
              element={
                <Modal>
                  <AdminPurchaseOrderUpdate />
                </Modal>
              }
            />
          </Route>
          <Route
            path="usage-credits/purchase-order/:purchaseOrderId/gift-card/:giftCardId"
            element={<AdminResellerCodeGiftCardDetail />}
          />
          <Route
            path="users"
            element={
              <>
                <AdminUsers />
                <Outlet />
              </>
            }
          >
            <Route
              path=":userId/edit"
              element={
                <Modal>
                  <AdminEditUser />
                </Modal>
              }
            />
          </Route>
          <Route path="provisioning" element={<AdminProvisioning />} />
          <Route
            path="features"
            element={
              <>
                <AdminFeatures />
                <Outlet />
              </>
            }
          ></Route>
          <Route
            path="agents"
            element={
              <>
                <AdminInternalSkills />
                <Outlet />
              </>
            }
          >
            <Route
              path=":skillId"
              element={
                <Modal>
                  <Outlet />
                </Modal>
              }
            >
              <Route path="edit" element={<AdminEditInternalSkill />} />
              <Route path="versions/add" element={<AdminCreateInternalSkillVersion />} />
              <Route path="versions/:skillVersionId" element={<AdminEditInternalSkillVersion />} />
            </Route>
          </Route>
          <Route path="skill-builder-content">
            <Route index element={<AdminSkillBuilderTemplates />} />
            <Route path="config" element={<AdminSkillBuilderConfig />} />
            <Route path="templates/new" element={<AdminSkillBuilderTemplateNew />} />
            <Route path="templates/:id" element={<AdminSkillBuilderTemplateDetail />} />
            <Route path="recipes/new" element={<AdminSkillBuilderRecipeNew />} />
            <Route path="recipes/:id" element={<AdminSkillBuilderRecipeDetail />} />
            <Route path="recipes" element={<AdminSkillBuilderRecipes />} />
            <Route path="beta-nodes/new" element={<AdminSkillBuilderBetaNodeNew />} />
            <Route path="beta-nodes/:id" element={<AdminSkillBuilderBetaNodeDetail />} />
            <Route path="beta-nodes" element={<AdminSkillBuilderBetaNodes />} />
            <Route path="models" element={<AdminSkillBuilderModels />} />
          </Route>
        </Route>

        {/* Auth Routes */}
        <Route
          path="/actions/authorize"
          element={<ProtectedRoute authenticate element={<AuthorizeAction />} />}
        />

        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/authorize" element={<AuthCallback />} />

        {/* Misc Routes */}
        <Route path="/eula" element={<SoftwareAgreement />} />
        <Route path="/help" element={<ExternalRedirect to={supportUrl} replace />} />

        {/* Legal Routes */}
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<Privacy />} />

        {/* Fallback Routes */}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}
