import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { isEmpty } from 'lodash';
import { useState } from 'react';

import { Card, Button, Icon, IconButton, Spinner, Text } from '@optra/kit';

import BillingAddressCard from 'components/billing-address-card';
import BillingAddressForm from 'components/billing-address-form';
import CreditCardInput from 'components/credit-card-input';
import FlexCenter from 'components/flex-center';
import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import PaymentMethodIcon from 'components/payment-method-icon';
import PaymentMethodLabel from 'components/payment-method-label';
import { api, q, useOnSuccess } from 'config/api';
import { useDevicePayment } from 'queries';

export default function DevicePaymentForm({ deviceId, onComplete }) {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();

  const [hasBillingAddress, setHasBillingAddress] = useState(false);
  const [hasPaymentMethods, setHasPaymentMethods] = useState(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState();

  const stripe = useStripe();
  const elements = useElements();

  const {
    data,
    isLoading: loading,
    isSuccess,
    error: fetchError,
    refetch,
  } = useDevicePayment(deviceId);

  const qc = q.useQueryClient();
  const createSetupIntent = q.useMutation({
    mutationFn: () =>
      api(
        `mutation createSetupIntent {
          clientSecret: createSetupIntent
        }`,
      ),
  });

  const addToSubscription = q.useMutation({
    mutationFn: form =>
      api(
        `mutation addToSubscription($form: addToSubscriptionForm!) {
          addToSubscription(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['devices'] });
      qc.invalidateQueries({ queryKey: ['device', deviceId] });
      onComplete();
    },
  });

  const restoreFromSubscription = q.useMutation({
    mutationFn: form =>
      api(
        `mutation restoreFromSubscription($form: restoreFromSubscriptionForm!) {
          restoreFromSubscription(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['device', deviceId] });
      onComplete();
    },
  });

  const handleNewPaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { clientSecret } = await createSetupIntent.mutateAsync();

    const cardElement = elements.getElement(CardElement);
    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        type: 'card',
        card: cardElement,
      },
    });

    if (error) {
      setError();
      return false;
    } else {
      return setupIntent;
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setError(null);
    setSaving(true);

    try {
      let form = {};
      if (!hasPaymentMethods) {
        const setupIntent = await handleNewPaymentMethod();
        form = {
          nodeId: deviceId,
          nodeType: 'devices',
          stripeSetupIntentId: setupIntent?.id,
        };
      } else if (selectedPaymentMethodId) {
        form = {
          nodeId: deviceId,
          nodeType: 'devices',
          stripePaymentMethodId: selectedPaymentMethodId,
        };
      }

      if (data.device?.subscriptionStatus === 'expiring') {
        // NOTE: This undoes a cancelation that has not yet been executed
        await restoreFromSubscription.mutateAsync(form);
      } else {
        // NOTE: This immediately bills the user
        await addToSubscription.mutateAsync(form);
      }
    } catch (err) {
      setError(err);
    } finally {
      setSaving(false);
    }
  };

  useOnSuccess(
    () => {
      if (!isEmpty(data?.paymentMethods)) {
        setHasPaymentMethods(true);
        setSelectedPaymentMethodId(data?.paymentMethods?.[0]?.id);
      }

      if (!isEmpty(data?.currentBillingAddress?.zip)) {
        setHasBillingAddress(true);
      }
    },
    { isSuccess },
    [data],
  );

  if (!loading && !hasBillingAddress) {
    return (
      <BillingAddressForm
        billingAddress={data?.currentBillingAddress}
        onComplete={() => refetch()}
      />
    );
  }

  return (
    <>
      <ModalBody as="form" onSubmit={handleSubmit} className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Create Subscription">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Device">
            {fetchError.message}
          </Message>
        )}

        {loading && (
          <Card variant="secondary">
            <FlexCenter>
              <Spinner size="lg" color="gradient" />
            </FlexCenter>
          </Card>
        )}

        {!loading && hasPaymentMethods && (
          <div>
            <Text variant="label" color="muted" className="block text-xs mb-2">
              Payment Method
            </Text>
            <List>
              {data?.paymentMethods?.map(paymentMethod => (
                <ListItem
                  key={paymentMethod?.id}
                  onClick={() => setSelectedPaymentMethodId(paymentMethod?.id)}
                  renderLeft={() =>
                    selectedPaymentMethodId === paymentMethod?.id ? (
                      <Icon name="CheckCircle" color="gradient" />
                    ) : (
                      <Icon name="Circle" color="gray" weight="line" />
                    )
                  }
                  renderRight={() => (
                    <IconButton
                      size="sm"
                      variant="secondary"
                      name="Pencil"
                      to={`${paymentMethod.id}/edit`}
                      state={{ fromModal: true }}
                    />
                  )}
                >
                  <div className="flex items-center space-x-2">
                    <PaymentMethodIcon brand={paymentMethod?.processor} />
                    <PaymentMethodLabel
                      brand={paymentMethod?.processor}
                      last4={paymentMethod?.last4}
                      expiration={paymentMethod?.expiration}
                      isDefault={paymentMethod?.default}
                    />
                  </div>
                </ListItem>
              ))}
              <ListItem
                to="new-credit-card"
                state={{ fromModal: true }}
                renderLeft={() => <Icon name="PlusCircle" color="gray" weight="line" />}
                renderRight={() => <Icon name="CaretRight" weight="line" />}
              >
                <Text>New Credit Card</Text>
              </ListItem>
            </List>
          </div>
        )}

        <div style={{ display: hasPaymentMethods ? 'none' : 'block' }}>
          <CreditCardInput disabled={saving} />
        </div>

        {!loading && hasBillingAddress && (
          <BillingAddressCard
            billingAddress={data?.currentBillingAddress}
            editPath="edit-billing-address"
            editPathState={{
              fromModal: true,
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSubmit} size="xl" loading={loading || saving}>
          {data?.device?.subscriptionStatus === 'expiring' ? 'Resubscribe' : 'Subscribe'}
        </Button>
      </ModalFooter>
    </>
  );
}
