import { api, q } from 'config/api';

export const marketplaceFragment = `
  id
  originSkillId
  createdAt
  name
  icon
  iconUrl
  screenshotUrls
  description
  summary
  color
  licenseUrl
  manualUrl
  owned
  purchased
  published
  requiresContact
  hidden
  tags {
    id
    name
    icon
  }
  price {
    amount
  }
  developerProfile {
    name
    website
    privacyPolicy
    logoUrl
  }
  latestVersion {
    publicationState
    version
    releaseNotes
    storage
    env
    port
    devices {
      sound
      hdmi
    }
    ... on MarketplaceSkillVersion {
      compatibleDeviceModels
    }
  }
  latestRecommendedVersion {
    publicationState
    version
    releaseNotes
    storage
    env
    port
    devices {
      sound
      hdmi
    }
    ... on MarketplaceSkillVersion {
      compatibleDeviceModels
    }
  }
`;

export function useMarketplaceSkills(variables) {
  return q.useInfiniteQuery({
    queryKey: ['marketplaceSkills', variables],
    queryFn({ pageParam }) {
      return api(
        `query marketplaceSkills($list: listFields) {
          list: marketplaceSkills (list: $list) {
            data {
              ${marketplaceFragment}
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 5000,
  });
}
