import { api, q } from 'config/api';

export default function useLibrarySkills(variables) {
  return q.useInfiniteQuery({
    queryKey: ['librarySkills', variables],
    queryFn({ pageParam }) {
      return api(
        `query librarySkills($list: listFields) {
          list: librarySkills (list: $list) {
            data {
              __typename
              id
              name
              icon
              iconUrl
              color
              latestVersion {
                id
                version
                publicationState
                synthesisState
              }
              developerProfile {
                id
                name
                website
                privacyPolicy
                logoUrl
              }
              isSkillBuilder
              isSkillBuilderV2
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 5000,
  });
}
