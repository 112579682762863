import { useParams, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';

import DevicePaymentForm from 'components/device-payment-form';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';

export default function DevicePayment() {
  useTitle('Subscribe to Device | Optra');
  const { deviceId } = useParams();
  const navigate = useNavigate();

  return (
    <ModalInner>
      <ModalTitle title="Subscribe to Device" icon="CreditCard" />
      <DevicePaymentForm
        deviceId={deviceId}
        onComplete={() => {
          navigate('..', {
            state: {
              fromModal: false,
            },
          });
        }}
      />
    </ModalInner>
  );
}
