import isEmpty from 'lodash/isEmpty';

import { Button } from '@optra/kit';

export default function MarketplaceSkillTabs({ currentTab = 'listing', marketplaceSkill }) {
  if (isEmpty(marketplaceSkill?.manualUrl)) return null;

  return (
    <div className="flex items-center justify-center">
      <Button
        variant={currentTab === 'listing' ? 'primary' : 'tertiary'}
        to="?tab=listing"
        icon="SquaresFour"
        size="sm"
        className="rounded-r-none"
      >
        Listing
      </Button>
      <Button
        variant={currentTab === 'manual' ? 'primary' : 'tertiary'}
        to="?tab=manual"
        icon="FileText"
        size="sm"
        className="rounded-l-none relative"
      >
        Manual
      </Button>
    </div>
  );
}
