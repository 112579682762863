import { isEmpty } from 'lodash';
import { useState } from 'react';

import { Card, Button, Spinner, Text } from '@optra/kit';

import BillingAddressForm from 'components/billing-address-form';
import FlexCenter from 'components/flex-center';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import { useOnSuccess } from 'config/api';
import formatPennies from 'lib/format-pennies';
import pluralize from 'lib/pluralize';
import { useDeviceSubscription } from 'queries';

import PortalSubscriptionFeatures from './portal-subscription-features';

export default function DeviceSubscriptionDetails({ deviceId, nextPath }) {
  const [hasBillingAddress, setHasBillingAddress] = useState(false);

  const {
    data,
    isLoading: fetching,
    isSuccess,
    error: fetchError,
    refetch,
  } = useDeviceSubscription(deviceId);

  useOnSuccess(
    () => {
      if (!isEmpty(data?.currentBillingAddress?.zip)) {
        setHasBillingAddress(true);
      }
    },
    { isSuccess },
    [data],
  );

  if (fetching) {
    return (
      <ModalBody>
        <Card variant="secondary">
          <FlexCenter>
            <Spinner size="lg" color="gradient" />
          </FlexCenter>
        </Card>
      </ModalBody>
    );
  }

  if (fetchError) {
    return (
      <ModalBody>
        <Message variant="danger" title="Couldn't Load Subscription">
          {fetchError.message}
        </Message>
      </ModalBody>
    );
  }

  if (!fetching && !hasBillingAddress) {
    return (
      <BillingAddressForm
        billingAddress={data?.currentBillingAddress}
        onComplete={() => {
          refetch();
        }}
      />
    );
  }

  const dueToday = data.device?.subscriptionStatus === 'expiring' ? 0 : data?.dueToday?.total;
  const lineItems = data?.subscription?.lineItems?.filter?.(
    li => li.type !== 'tax' && li.quantity > 0,
  );
  const taxLineItem = data?.subscription?.lineItems?.find?.(li => li.type === 'tax');

  const nextTotalMonthlyPrice = !isEmpty(data.subscription)
    ? data.subscription?.lineItems?.reduce((acc, li) => {
        return acc + (li.price?.amount || 0) * (li.quantity || 0);
      }, 0) +
      data.device?.price?.amount +
      data.device?.subscriptionTax
    : dueToday;

  return (
    <ModalInner>
      <ModalBody className="space-y-4">
        <PortalSubscriptionFeatures />

        <Card variant="secondary" className="flex space-x-4">
          <div className="flex-1">
            {lineItems?.map(lineItem => (
              <div className="flex items-center justify-between" key={lineItem?.id}>
                <Text color="muted" className="text-sm">
                  <span className="font-bold">{lineItem?.quantity}x</span> {lineItem?.price?.name}{' '}
                  {pluralize('Device', lineItem?.quantity)}
                </Text>
                <Text color="muted">{`${formatPennies(
                  lineItem?.quantity * lineItem?.price?.amount,
                )} / mo`}</Text>
              </div>
            ))}

            {taxLineItem && (
              <div className="flex items-center justify-between" key={taxLineItem?.id}>
                <Text color="muted" className="text-sm">
                  <span className="font-bold">Tax</span>
                </Text>
                <Text color="muted">{`${formatPennies(
                  (taxLineItem?.price?.amount || 0) * (taxLineItem?.quantity || 0),
                )} / mo`}</Text>
              </div>
            )}

            <div className="flex items-center justify-between">
              <Text color="muted" className="text-sm">
                {data?.subscription?.lineItems?.length > 0 ? (
                  <span className="font-bold">+1</span>
                ) : (
                  <span className="font-bold">1x</span>
                )}{' '}
                {data.device?.price?.name} Device
              </Text>
              <Text color="muted">{`${formatPennies(data.device?.price?.amount)} / mo`}</Text>
            </div>

            <div className="flex items-center justify-between">
              <Text color="muted" className="text-sm">
                <span className="font-bold">+</span> Tax
              </Text>
              <Text color="muted">{`${formatPennies(data.device?.subscriptionTax)} / mo`}</Text>
            </div>

            <div className="flex items-center justify-between">
              <Text className="text-sm font-bold">Subscription Total</Text>
              <Text className="font-bold" color="gradient">{`${formatPennies(
                nextTotalMonthlyPrice,
              )} / mo`}</Text>
            </div>
          </div>
        </Card>

        <div className="flex items-center justify-between px-4">
          <Text className="text-sm font-bold">Amount Due Today</Text>
          <Text color="gradient" className="font-bold" size="xl">
            {formatPennies(dueToday)}{' '}
            {data?.dueToday?.prorated && <span className="text-xs">(Prorated)</span>}
          </Text>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex flex-col items-center justify-center space-y-2">
          <Button to={nextPath} state={{ fromModal: true }}>
            Subscribe
          </Button>
          <Text size="xs" color="muted" className="my-2 block text-center">
            Recurring billing. Cancel anytime.
          </Text>
        </div>
      </ModalFooter>
    </ModalInner>
  );
}
