import noop from 'lodash/noop';

import { Card } from '@optra/kit';

import DeprecateMarketplaceSkillVersionButton from 'components/deprecate-marketplace-skill-version-button';
import Label from 'components/label';
import Select from 'components/select';
import Textarea from 'components/textarea';
import ValidationError from 'components/validation-error';
import formatPublicationState from 'lib/format-publication-state';

export default function PublishSkillVersion(props) {
  const {
    onChangeVersion = noop,
    form,
    versions,
    marketplaceVersions,
    isLoading,
    disabled,
    setError = noop,
  } = props;

  return (
    <>
      <Card variant="secondary" className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="versionId">Version</Label>
          <Select
            {...form.register('versionId', { onChange: onChangeVersion })}
            readOnly={isLoading || form.formState.isSubmitting}
          >
            {versions?.map?.(version => (
              <option key={version.id} value={version.id}>
                {version.version}{' '}
                {`[${formatPublicationState(version.publicationState).toUpperCase()}]`}
                {version.deprecated ? ' [DEPRECATED]' : ''}
              </option>
            ))}
          </Select>
        </div>
        <div className="space-y-2">
          <Label htmlFor="releaseNotes">Release Notes</Label>
          <Textarea
            rows={6}
            {...form.register('releaseNotes', {
              required: 'Please enter release notes.',
            })}
            readOnly={form.formState.isSubmitting}
            disabled={disabled}
          />
          <ValidationError errors={form.formState.errors} name="releaseNotes" />
        </div>
      </Card>
      <div className="space-y-2">
        <div className="space-y-2">
          <DeprecateMarketplaceSkillVersionButton
            form={form}
            versions={versions}
            marketplaceVersions={marketplaceVersions}
            setError={setError}
          />
        </div>
      </div>
    </>
  );
}
