import { api, q } from 'config/api';

export default function useWorkflowsSkills(id, variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['workflow', id, variables, 'skills'],
    queryFn({ pageParam }) {
      return api(
        `query workflowsSkills($id: ID!, $list: listFields) {
          workflow(id: $id) {
            id
            list: skills(list: $list) {
              count
              data {
                id
                deleted
                disabled {
                  disabled
                  reasons
                }
                synchronized
                isSynchronizing
                name
                updateAvailable
                skill {
                  id
                  name
                  icon
                  iconUrl
                  color
                  developerProfile {
                    name
                  }
                }
              }
              cursor {
                after
              }
            }
          }
        }`,
        {
          ...variables,
          id,
          list: {
            limit: 12,
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.workflow?.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 60000,
    enabled: !!id,
    ...overrides,
  });
}
