import _isFinite from 'lodash/isFinite';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';

import {
  Avatar,
  Card,
  Dropdown,
  DROPDOWN_MENU_PLACEMENT,
  Button,
  Icon,
  IconButton,
  Text,
  Toggle,
} from '@optra/kit';

import { useFeature } from 'components/feature';
import Input from 'components/input';
import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import OrganizationSelect from 'components/organization-select';
import { api, q } from 'config/api';
import formatCredits from 'lib/format-credits';
import { useCurrentBillingAccount, useCurrentOrganization, usePinnedPage } from 'queries';
import useGlobalState from 'state';

const NUM_MAX_PERSONAL_ORGS = 3;

function ToggleMFA() {
  const qc = q.useQueryClient();
  const current = q.useQuery({
    queryKey: ['mfa'],
    queryFn: async () => {
      const r = await api(
        `query mfaEnabled {
          currentUser {
            profile {
              mfaEnabled
              mfaEnforced
            }
          }
        }`,
      );
      return r.currentUser?.profile;
    },
    retry: 0,
  });

  const updateMFA = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateMFA($form: updateMFAForm!) {
          user: updateMFA(form: $form) {
            mfaEnabled
          }
        }`,
        { form },
      ),
    async onMutate(form) {
      await qc.cancelQueries({ queryKey: ['mfa'] });
      qc.setQueryData(['mfa'], { ...current.data, mfaEnabled: form.enabled });
      return true;
    },
    onError: () => {
      qc.setQueryData(['mfa'], current.data);
    },
    onSettled() {
      qc.invalidateQueries({ queryKey: ['mfa'] });
    },
  });

  function toggle(e) {
    e.stopPropagation();
    e.preventDefault();
    updateMFA.mutate({
      enabled: !current.data?.mfaEnabled,
    });
  }

  return (
    <Toggle
      onChange={toggle}
      checked={current.data?.mfaEnforced || current.data?.mfaEnabled || false}
      controlled
      stopPropagation
      disabled={current.isLoading || updateMFA.isPending || current.data?.mfaEnforced}
      label="MFA"
    />
  );
}

function useProfile() {
  return q.useQuery({
    queryKey: ['profile'],
    queryFn: () =>
      api(
        `query profile {
          currentOwner {
            ... on UserProfile {
              pricing
            }
            ... on Organization {
              pricing
            }
          }
          currentOrganizationMember {
            roles
          }
          currentUser {
            id
            email
            profile {
              id
              name
              image
              ownedOrganizationsCount
            }
            memberships {
              data {
                id
                roles
                organization {
                  id
                  name
                }
              }
            }
          }
        }`,
      ),
    retry: 0,
  });
}

export default function Profile() {
  useTitle('Profile | Optra');
  const navigate = useNavigate();
  const [currentOrganization, { handleChange: handleChangeCurrentOrg }] = useCurrentOrganization();
  const { isLoading, data: profile } = useProfile();

  const billingAccount = useCurrentBillingAccount({
    refetchInterval: 10000,
    retry: 3,
  });
  const currentBillingAccount = billingAccount?.data?.currentBillingAccount;
  const balances = currentBillingAccount?.balances;
  const estimatedDaysRemaining = Math.abs(balances?.dailyUsage / balances?.credits) || 0;

  const [userColorScheme, setUserColorScheme] = useGlobalState('userColorScheme');
  const [pinnedLandingPage, { isLoading: isLoadingPinnedPage, setPinnedPage }] = usePinnedPage();
  const resellerCodesEnabled = useFeature('resellerCodes', 'global');
  const accessSecretsEnabled = useFeature('accessSecrets');

  const getRemainingCreditPercentageColor = percentage => {
    if (percentage > 66) {
      return 'bg-primary';
    } else if (percentage > 33) {
      return 'bg-yellow';
    }
    return 'bg-red';
  };

  const qc = q.useQueryClient();

  const handleLogOut = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      navigate('/logout');
    }
  };

  const { handleSubmit, register, reset } = useForm({
    code: '',
  });

  const redeemCode = q.useMutation({
    mutationFn: form =>
      api(
        `mutation redeemGiftCard($code: String!) {
          redeemGiftCard(code: $code) {
            id
          }
        }`,
        { code: form.code },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['currentBillingAccount'] });
    },
  });

  const handleKeyRedeemSubmit = handleSubmit(form => {
    redeemCode.mutate(
      {
        code: form.code,
      },
      {
        onSuccess() {
          reset();
        },
      },
    );
  });

  const currentUser = profile?.currentUser;
  const currentOwner = profile?.currentOwner;
  const canEditCurrentOrg = profile?.currentOrganizationMember?.roles?.includes('admin');
  const currentOrgIsPersonal = currentOrganization.id === '$$NONE';
  const numberOfOwnedOrgs = currentUser?.profile?.ownedOrganizationsCount || 0;

  return (
    <>
      <Card variant="secondary">
        <div className="flex space-x-4">
          <div className="flex-shrink-0">
            <Avatar src={currentUser?.profile?.image} alt={currentUser?.profile?.name} />
          </div>
          <div className="flex-1 flex-shrink-0 space-y-2">
            <div className="flex flex-row items-center justify-between">
              <Text size="xl" className="block">
                {currentUser?.profile?.name}
              </Text>
              <Text size="xs" color="muted" className="block mb-2">
                {currentUser?.profile?.email}
              </Text>
              <ToggleMFA />
            </div>
            <div className="flex flex-row items-center justify-between">
              {isLoading && <Text size="xs">Loading…</Text>}
              {!isLoading && currentUser?.memberships?.data?.length < 1 && (
                <Dropdown
                  placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
                  components={{
                    button: ({ isOpen }) => (
                      <div className="flex flex-row items-center space-x-2">
                        <Icon name="User" size="sm" />
                        <Text size="xs" variant="label" color="muted">
                          Personal Account
                        </Text>
                        <Icon name={isOpen ? 'CaretUp' : 'CaretDown'} size="xs" />
                      </div>
                    ),
                    body: (
                      <Dropdown.Item
                        to="/profile/organizations/create"
                        icon="Plus"
                        text="Create Organization"
                      />
                    ),
                  }}
                />
              )}
              {currentUser?.memberships?.data?.length > 0 && (
                <div className="flex flex-row items-center space-x-2">
                  <OrganizationSelect
                    placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
                    variant="tertiary"
                    placeholderText="Personal"
                    allOptionText="Personal"
                    allOptionIcon="User"
                    allOptionValue="$$NONE"
                    size="xs"
                    value={currentOrganization.id}
                    onChange={handleChangeCurrentOrg}
                  >
                    <OrganizationSelect.Footer
                      to="/profile/organizations/create"
                      icon="Plus"
                      text="Create Organization"
                      hoverSubtle
                      className="!rounded-md"
                      components={{
                        after: (
                          <Text
                            variant="label"
                            size="xs"
                            color={numberOfOwnedOrgs >= NUM_MAX_PERSONAL_ORGS ? 'danger' : 'muted'}
                          >
                            {numberOfOwnedOrgs}/{NUM_MAX_PERSONAL_ORGS}
                          </Text>
                        ),
                      }}
                    />
                  </OrganizationSelect>
                  {!currentOrgIsPersonal && canEditCurrentOrg && (
                    <IconButton
                      to={`/profile/organizations/${currentOrganization?.id}/edit`}
                      name="Pencil"
                      variant="tertiary"
                    />
                  )}
                </div>
              )}
              <div className="flex space-x-2 justify-end">
                <Button variant="tertiary" size="xs" onClick={handleLogOut}>
                  Log Out
                </Button>
                {accessSecretsEnabled && currentOrgIsPersonal && (
                  <Button to="./api-keys" variant="tertiary" size="xs">
                    API Keys
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>

      {resellerCodesEnabled && currentOwner?.pricing === 'credit' && (
        <div>
          <Text variant="label" size="xs" className="mr-2">
            Credits
          </Text>
          <Card variant="secondary">
            {_isFinite(balances?.remainingPercentage) && (
              <>
                <div>
                  <div className="shadow-lg w-full bg-light-bg-secondary dark:bg-dark-bg-secondary rounded-full">
                    <div
                      className={`h-4 rounded-full ${
                        balances?.processing ? 'animate-pulse' : ''
                      } ${getRemainingCreditPercentageColor(balances?.remainingPercentage)}`}
                      style={{
                        width: `${
                          balances?.remainingPercentage < 2 ? 2 : balances?.remainingPercentage
                        }%`,
                      }}
                    />
                  </div>
                  <Text variant="label" size="xs">
                    {`${formatCredits(balances?.balance)}/${formatCredits(
                      balances?.credits,
                    )} remaining ${
                      estimatedDaysRemaining > 0
                        ? `(Estimated ${estimatedDaysRemaining.toFixed(2)} days remaining)`
                        : ''
                    }`}
                  </Text>
                </div>
                <hr className="my-4" />
              </>
            )}

            <div>
              <Text size="xs" variant="label" className="block mb-2">
                Redemption Code
              </Text>
              <form onSubmit={handleKeyRedeemSubmit}>
                {redeemCode.isSuccess && (
                  <Message variant="success" title="Code Successfully Redeemed!"></Message>
                )}
                {redeemCode.isError && (
                  <Message variant="danger" title="Couldn't Redeem Code">
                    {redeemCode.error?.message}
                  </Message>
                )}

                <div className="flex items-center justify-between">
                  <Input
                    type="text"
                    placeholder="Enter your code..."
                    className="mr-2"
                    {...register('code', { required: 'Please enter a code.' })}
                  />
                  <Button
                    size="sm"
                    variant="tertiary"
                    type="submit"
                    loading={redeemCode.isPending}
                    disabled={redeemCode.isPending}
                  >
                    Redeem
                  </Button>
                </div>
              </form>
            </div>
          </Card>
        </div>
      )}

      <div>
        <Text variant="label" size="xs" className="mr-2">
          Theme
        </Text>
        <List>
          <ListItem
            renderLeft={() => <Icon name="Browsers" size="sm" />}
            renderRight={() =>
              userColorScheme === 'auto' ? (
                <Icon name="CheckCircle" color="gradient" />
              ) : (
                <Icon name="Circle" weight="line" color="gray" />
              )
            }
            onClick={() => setUserColorScheme('auto')}
          >
            <Text>System</Text>
          </ListItem>
          <ListItem
            renderLeft={() => <Icon name="Sun" size="sm" />}
            renderRight={() =>
              userColorScheme === 'light' ? (
                <Icon name="CheckCircle" color="gradient" />
              ) : (
                <Icon name="Circle" weight="line" color="gray" />
              )
            }
            onClick={() => setUserColorScheme('light')}
          >
            <Text>Light</Text>
          </ListItem>
          <ListItem
            renderLeft={() => <Icon name="Moon" size="sm" />}
            renderRight={() =>
              userColorScheme === 'dark' ? (
                <Icon name="CheckCircle" color="gradient" />
              ) : (
                <Icon name="Circle" weight="line" color="gray" />
              )
            }
            onClick={() => setUserColorScheme('dark')}
          >
            <Text>Dark</Text>
          </ListItem>
        </List>
      </div>

      {!isLoadingPinnedPage && !!pinnedLandingPage && (
        <div>
          <Text size="xs" variant="label" className="block mb-2">
            Pinned Home Page
          </Text>
          <Card variant="secondary">
            <div className="flex items-center justify-between">
              <Text
                size="xs"
                className="bg-light-bg-secondary border-light-fg-secondary dark:bg-dark-bg-secondary dark:border-dark-fg-secondary text-green font-mono text-xs py-1 px-2 rounded-md shadow-sm"
              >
                {pinnedLandingPage}
              </Text>
              <Button
                size="xs"
                variant="secondary"
                icon="Trash"
                onClick={() => setPinnedPage.mutate(null)}
                loading={setPinnedPage.isPending}
              >
                Unpin
              </Button>
            </div>
          </Card>
        </div>
      )}
    </>
  );
}
