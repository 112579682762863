import { isFinite } from 'lodash';

export default function formatCredits(_n, precision = 4) {
  let n = _n;
  if (!isFinite(Number(_n))) n = 0;

  const formatted = Number(n.toFixed(precision));

  return formatted;
}
