import { isEmpty } from 'lodash';
import flatMap from 'lodash/flatMap';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce, useTitle } from 'react-use';

import { Button, Icon, Text, SearchField } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import Feature from 'components/feature';
import IntersectionLoadMore from 'components/intersection-load-more';
import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillIcon from 'components/skill-icon';
import { useLibrarySkills } from 'queries';

// TODO: Pagination
export default function AddWorkflowSkill() {
  useTitle('Add Skill | Optra');
  const { workflowId } = useParams();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const librarySkills = useLibrarySkills(
    !isEmpty(debouncedSearch) ? { list: { filter: { $search: debouncedSearch } } } : {},
  );
  const skills = flatMap(librarySkills.data?.pages, page => page?.list?.data);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );
  const createWorkflowActionUrl = `/workflows/${workflowId}/skills/create-options`;

  return (
    <ModalInner>
      <ModalTitle
        title="Add Skill"
        icon="PlusCircle"
        loading={librarySkills.isLoading}
        renderActions={() => (
          <Feature
            authorizedFlags={['skillBuilder', 'dockerCompose']}
            renderOff={() => (
              <Button
                to={`/workflows/${workflowId}/skills/create-docker-image`}
                state={{
                  fromModal: true,
                }}
                variant="secondary"
                size="xs"
                icon="Plus"
              >
                New Skill
              </Button>
            )}
          >
            <Button
              to={createWorkflowActionUrl}
              state={{
                fromModal: true,
              }}
              variant="secondary"
              size="xs"
              icon="Plus"
            >
              New Skill
            </Button>
          </Feature>
        )}
      />
      <ModalBody>
        {librarySkills.error && (
          <Message variant="danger" title="Couldn't Load Skills">
            {librarySkills.error.message}
          </Message>
        )}

        <div className="my-4 flex items-center justify-between space-x-2">
          <SearchField
            className="flex-1"
            value={search}
            placeholder="Search Skills…"
            onChange={setSearch}
            searching={!librarySkills.isFetched && !isEmpty(search)}
            count={librarySkills.isFetched && !isEmpty(search) ? skills.length : null}
          />
        </div>

        {(!skills || skills.length < 1) && librarySkills.isFetched && (
          <EmptyContentMessage
            icon="Cube"
            title="No Skills Available"
            renderAction={() => (
              <Button
                to={createWorkflowActionUrl}
                state={{
                  fromModal: true,
                }}
                icon="Plus"
              >
                Create Skill
              </Button>
            )}
          />
        )}

        <List loading={librarySkills.isLoading}>
          {skills?.length > 0 &&
            skills?.map(skill => (
              <ListItem
                key={skill.id}
                to={`/workflows/${workflowId}/workflow-skills/create/${skill?.id}`}
                state={{
                  fromModal: true,
                }}
                renderLeft={() => (
                  <SkillIcon
                    size="sm"
                    color={skill?.color}
                    icon={skill?.icon}
                    iconUrl={skill?.iconUrl}
                  />
                )}
                renderRight={() => <Icon name="CaretRight" weight="line" />}
              >
                <Text>{skill.name}</Text>
              </ListItem>
            ))}
          <IntersectionLoadMore
            onVisible={librarySkills.fetchNextPage}
            disabled={librarySkills.isFetchingNextPage || !librarySkills.hasNextPage}
          />
        </List>
      </ModalBody>
    </ModalInner>
  );
}
