import { Card, Button, Text } from '@optra/kit';

export default function BillingAddressCard({ billingAddress = {}, editPath, editPathState }) {
  return (
    <div>
      <Text variant="label" color="muted" className="block text-xs mb-2">
        Billing Address
      </Text>

      <Card variant="secondary" className="flex items-center justify-between">
        <div className="flex-1">
          <Text className="block">{billingAddress?.name}</Text>
          <Text className="block text-xs">{billingAddress?.line1}</Text>
          <Text className="block text-xs">{billingAddress?.line2}</Text>
          <Text className="block text-xs">
            {[billingAddress?.city, billingAddress?.state].filter(Boolean).join(', ')}
          </Text>
          <Text className="block text-xs">
            {billingAddress?.zip} {billingAddress?.country}
          </Text>
        </div>

        <Button
          to={editPath}
          state={editPathState}
          variant="tertiary"
          size="xs"
          icon="Pencil"
          className="mt-4"
        >
          <Text>Edit</Text>
        </Button>
      </Card>
    </div>
  );
}
