import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Card, Button, Text } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import Select from 'components/select';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import usaStates from 'lib/usa-states';

export default function BillingAddressForm({ billingAddress, onComplete }) {
  const [error, setError] = useState();

  const qc = q.useQueryClient();
  const updateBillingAddress = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateBillingAddress($form: nameAddress!) {
          updateBillingAddress(form: $form) {
            name
            country
            state
            city
            zip
            line1
            line2
          }
        }`,
        { form },
      ),
    onSuccess(res) {
      qc.invalidateQueries({ queryKey: ['billingAddress'] });
      onComplete(res);
    },
    onError(error) {
      setError(error);
    },
  });

  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      state: '',
      city: '',
      zip: '',
      line1: '',
      line2: '',
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    updateBillingAddress.mutate({
      ...form,
      country: 'US',
    });
  });

  useEffect(() => {
    if (!isEmpty(billingAddress)) {
      reset({
        name: billingAddress.name,
        state: billingAddress.state,
        city: billingAddress.city,
        zip: billingAddress.zip,
        line1: billingAddress.line1,
        line2: billingAddress.line2,
      });
    }
  }, [billingAddress, reset]);

  return (
    <>
      <ModalBody as="form" onSubmit={handleSubmit} className="space-y-4">
        {error && (
          <Message variant="danger" title="Error Saving Billing Address" className="mb-6">
            {error.message}
          </Message>
        )}

        <Text variant="label" color="muted" className="block text-xs mb-2">
          Billing Address
        </Text>

        <Card variant="secondary" className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Full Name</Label>
            <Input
              type="text"
              {...register('name', { required: 'Please enter a name.' })}
              readOnly={updateBillingAddress.isPending}
            />
            <ValidationError errors={errors} name="name" />
          </div>

          <div className="space-y-2">
            <Label htmlFor="line1">Address Line 1</Label>
            <Input
              type="text"
              {...register('line1', { required: 'Please enter Address Line 1.' })}
              readOnly={updateBillingAddress.isPending}
            />
            <ValidationError errors={errors} name="line1" />
          </div>

          <div className="space-y-2">
            <Label htmlFor="line2">Address Line 2</Label>
            <Input type="text" {...register('line2')} readOnly={updateBillingAddress.isPending} />
            <ValidationError errors={errors} name="line2" />
          </div>

          <div className="space-y-2">
            <Label htmlFor="city">City</Label>
            <Input
              type="text"
              {...register('city', { required: 'Please enter a city.' })}
              readOnly={updateBillingAddress.isPending}
            />
            <ValidationError errors={errors} name="city" />
          </div>

          <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4">
            <div className="flex-1 space-y-2">
              <Label htmlFor="state">State</Label>
              <Select
                name="state"
                disabled={updateBillingAddress.isPending}
                {...register('state', { required: 'Choose a valid state.' })}
              >
                <option value="">Choose…</option>
                {usaStates.map(s => (
                  <option key={s?.code} value={s?.code}>
                    {s?.name}
                  </option>
                ))}
              </Select>
              <ValidationError errors={errors} name="state" />
            </div>
            <div className="flex-1 space-y-2">
              <Label htmlFor="zip">Zip</Label>
              <Input
                type="text"
                {...register('zip', { required: 'Please enter a zip.' })}
                readOnly={updateBillingAddress.isPending}
              />
              <ValidationError errors={errors} name="zip" />
            </div>
          </div>
        </Card>
      </ModalBody>

      <ModalFooter>
        <Button
          type="submit"
          size="xl"
          onClick={handleSubmit}
          loading={updateBillingAddress.isPending}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
