import { api, q } from 'config/api';

export default function useDeviceSubscription(id, overrides) {
  return q.useQuery({
    queryKey: ['device', id, 'subscription'],
    queryFn: () =>
      api(
        `query deviceSubscription($id: ID!) {
          device(id: $id) {
            model
            subscriptionStatus
            price(type: recurring) {
              id
              amount
              name
            }
            subscriptionTax
          }
          currentBillingAddress {
            name
            country
            state
            city
            zip
            line1
            line2
          }
          dueToday: addToSubscriptionAmountDueToday(id: $id, type: devices) {
            total
            prorated
          }
          subscription: paymentSubscription {
            lineItems: subscriptions {
              id
              type
              price {
                amount
                name
              }
              quantity: nextBillingCycleQuantity
            }
          }
        }`,
        { id },
      ),
    retry: false,
    enabled: !!id,
    ...overrides,
  });
}
