import { Transition } from '@headlessui/react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { useState, Fragment } from 'react';
import { useAuth0 } from 'react-auth0rize';
import { Link, NavLink, useLocation } from 'react-router-dom';

import {
  Alert,
  Avatar,
  Button,
  Icon,
  IconButton,
  Spinner,
  Logo,
  Container,
  ProgressCircle,
  InteractiveWrap,
  ButtonWrap,
  Tooltip,
  Text,
} from '@optra/kit';

import NotificationsNavItem from 'components/notifications-nav-item';
import { useImpersonation } from 'hooks';
import { useFeatureFlags, matchFlags } from 'lib/feature-flags';
import { useRoles } from 'providers/roles-provider';
import {
  useCurrentUser,
  usePinnedPage,
  useCurrentOrganization,
  useCurrentBillingAccount,
} from 'queries';

export function isActiveTab(path = '', currentPath = '') {
  return path.replace('/', '') === currentPath.split('/')[1];
}

export default function Header({ onClickNotifications, onClickLogs, onClickSupport }) {
  const location = useLocation();
  const auth = useAuth0();
  const [currentUser] = useCurrentUser();
  const [roles, isPersonal, isSysAdmin, loadingMemberRoles] = useRoles();
  const billingAccount = useCurrentBillingAccount();
  const isOrgAdmin = roles.includes('admin') || isSysAdmin;
  const viewChartOnly = roles.includes('chartViewerOnly');
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { stopImpersonation, assumedUser, assumedOrganizationId } = useImpersonation();
  const [organization, { fetching: organizationIsLoading }] = useCurrentOrganization();
  const flags = useFeatureFlags();
  const [pinnedLandingPage, { isLoading: loadingPinnedPage }] = usePinnedPage();
  const currentBillingAccount = billingAccount?.data?.currentBillingAccount;
  const remainingCreditPercentage = currentBillingAccount?.remainingPercentage;

  const mainNavItems = [
    {
      to: '/workflows',
      label: 'Workflows',
    },
    {
      to: '/devices',
      label: 'Devices',
    },
    {
      to: '/skills',
      label: 'Skills',
    },
    {
      to: '/charts',
      label: 'Charts',
      requiresFeature: 'timestream',
      requiresAnyRole: ['admin', 'chartEditor', 'chartViewerOnly'],
      allowChartViewer: true,
    },
    {
      to: '/users',
      label: 'Users',
      requiresAdmin: true,
    },
    {
      to: '/admin/organizations',
      label: 'Admin',
      requiresSuperAdmin: true,
    },
  ];

  const secondaryNavItems = [
    {
      label: 'Notifications',
      icon: 'Bell',
      onClick: onClickNotifications,
      allowChartViewer: true,
    },
    {
      label: 'Logs',
      icon: 'TerminalWindow',
      onClick: onClickLogs,
      allowChartViewer: false,
    },
    {
      label: 'Support',
      icon: 'Question',
      onClick: onClickSupport,
      allowChartViewer: true,
    },
    {
      label: 'Profile',
      icon: 'User',
      to: '/profile',
      allowChartViewer: true,
    },
  ];

  const shouldRenderNavItem = navItem => {
    const {
      requiresAdmin = false,
      requiresSuperAdmin = false,
      requiresFeature,
      requiresAnyRole,
      allowChartViewer,
    } = navItem;
    if (requiresFeature) {
      const [isMatch] = matchFlags(flags, [requiresFeature], true);
      if (!isMatch) return false;
    }

    if (requiresAdmin && (!isOrgAdmin || isPersonal)) {
      return false;
    }

    if (requiresSuperAdmin && !isSysAdmin) {
      return false;
    }

    const dependsOnData =
      !isEmpty(requiresAnyRole) ||
      allowChartViewer === false ||
      !isEmpty(requiresFeature) ||
      requiresAdmin ||
      requiresSuperAdmin;
    if (dependsOnData && loading) return false;

    const hasAnyRequiredRole = roles?.some?.(role => requiresAnyRole?.includes?.(role));
    if (requiresAnyRole && (!isOrgAdmin || isPersonal) && !hasAnyRequiredRole) {
      return false;
    }

    if (!allowChartViewer && viewChartOnly) {
      return false;
    }

    return true;
  };

  const loading =
    assumedUser.isLoading || loadingMemberRoles || loadingPinnedPage || organizationIsLoading;

  let assumedText = !isEmpty(assumedUser.data?.name)
    ? `Using as ${assumedUser.data?.name}`
    : `Viewing organization ${organization?.name} as sys admin`;

  if (loading) assumedText = <Spinner size="xs" />;

  return (
    <>
      <header className={cx('relative py-4', auth?.authenticated ? 'opacity-100' : 'opacity-0')}>
        <Container full>
          {(!isEmpty(assumedUser.data?.id) || !isEmpty(assumedOrganizationId)) && (
            <Alert
              icon={null}
              text={assumedText}
              className="mb-4"
              components={{
                before: (
                  <Avatar src={assumedUser.data?.image} alt={assumedUser.data?.name} size="xs" />
                ),
                after: (
                  <Button
                    size="xs"
                    variant="plain"
                    icon={loading ? null : 'X'}
                    disabled={stopImpersonation.isLoading}
                    onClick={() => stopImpersonation.mutate()}
                  >
                    {stopImpersonation.isLoading ? <Spinner size="xs" /> : 'Stop Using'}
                  </Button>
                ),
              }}
            />
          )}
          <div className="flex items-center justify-center lg:justify-between">
            <div className="flex absolute left-3 divide-x dark:divide-black-500">
              <button
                type="button"
                className="appearance-none lg:hidden p-2"
                onClick={() => setMobileNavOpen(!mobileNavOpen)}
              >
                {mobileNavOpen ? <Icon name="X" /> : <Icon name="List" />}
              </button>
              {pinnedLandingPage && (
                <div className="pt-1 pl-4 ml-3 lg:hidden">
                  <Tooltip label="Home">
                    <ButtonWrap to={pinnedLandingPage}>
                      <Icon name="House" weight="duotone" color="primary" size="lg" />
                    </ButtonWrap>
                  </Tooltip>
                </div>
              )}
            </div>

            <div className="flex items-center flex-nowrap divide-x dark:divide-black-500">
              <ButtonWrap to="/">
                <Logo />
              </ButtonWrap>
              {pinnedLandingPage && (
                <div className="pl-4 ml-5 -mr-[4.3rem] hidden lg:block">
                  <Tooltip label="Home">
                    <ButtonWrap to={pinnedLandingPage}>
                      <Icon name="House" weight="duotone" color="primary" size="lg" />
                    </ButtonWrap>
                  </Tooltip>
                </div>
              )}
            </div>

            <nav className="hidden lg:flex items-center space-x-3">
              {mainNavItems.map(mainNavItem => {
                const { to, label } = mainNavItem;
                if (!shouldRenderNavItem(mainNavItem)) return null;
                const isActive = isActiveTab(to, location.pathname);
                return (
                  <InteractiveWrap
                    key={`desktop-${label}`}
                    as={NavLink}
                    to={to}
                    className={cx(
                      'py-3 px-5',
                      'rounded-md',
                      'text-sm tracking-wide',
                      'transition-color',
                      'text-black-900 dark:text-white',
                      'font-medium',
                      isActive
                        ? [
                            'bg-gradient-to-br',
                            'from-gray-100 to-gray-200/60',
                            'dark:from-white/10 dark:to-white/20',
                          ]
                        : [
                            'hover:bg-gradient-to-br',
                            'hover:from-gray-100 hover:to-gray-100',
                            'dark:hover:from-black-900 dark:hover:to-black-900',
                          ],
                    )}
                  >
                    {label}
                  </InteractiveWrap>
                );
              })}
            </nav>

            <div className="hidden lg:flex items-center space-x-2">
              {secondaryNavItems.map(secondaryNavItem => {
                const { icon, label, onClick, to } = secondaryNavItem;
                if (!shouldRenderNavItem(secondaryNavItem)) return null;

                if (label === 'Notifications') {
                  return (
                    <NotificationsNavItem
                      key="desktop-notifications"
                      onClick={onClickNotifications}
                    />
                  );
                }

                if (label === 'Profile') {
                  return (
                    <InteractiveWrap
                      to={to}
                      className={cx(
                        'flex',
                        'flex-row',
                        'items-center',
                        'space-x-2',
                        'py-1',
                        'px-2',
                        'rounded-full',
                        'border',
                        'shadow-sm',
                        'bg-light-bg-secondary',
                        'text-light-fg-primary',
                        'border-light-bg-primary',
                        'hover:bg-light-bg-primary',
                        'hover:border-light-bg-secondary',
                        'dark:bg-dark-bg-secondary',
                        'dark:text-dark-fg-primary',
                        'dark:border-dark-bg-primary',
                        'dark:hover:border-dark-bg-secondary',
                        'dark:hover:bg-dark-bg-primary',
                      )}
                      key="desktop-profile"
                    >
                      <div className="relative z-0">
                        <Avatar
                          src={currentUser?.image}
                          alt={currentUser?.name}
                          size="xs"
                          className="shadow-sm border border-light-bg-secondary dark:border-dark-fg-tertiary"
                        />
                        {remainingCreditPercentage && remainingCreditPercentage < 33 && (
                          <Tooltip label={`${remainingCreditPercentage}% of credits remaining`}>
                            <div className="absolute inset-0">
                              <ProgressCircle
                                size="md"
                                progress={remainingCreditPercentage / 100}
                                loading={false}
                                color="red"
                              />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                      <div className="flex flex-col pr-4">
                        <Text size="sm" variant="bold">
                          {currentUser?.name}
                        </Text>
                        <Text size="xs" color="muted">
                          {truncate(organization?.name, { length: 20 })}
                        </Text>
                      </div>
                    </InteractiveWrap>
                  );
                }

                return (
                  <IconButton
                    to={to}
                    key={`desktop-${label}`}
                    onClick={onClick}
                    name={icon}
                    variant="plain"
                    size="lg"
                  />
                );
              })}
            </div>
          </div>
        </Container>
        <div className={cx('absolute left-0 right-0 top-full z-top', 'lg:hidden')}>
          <Transition
            show={mobileNavOpen}
            as={Fragment}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <nav
              className={cx(
                'py-2',
                'bg-light-bg-primary dark:bg-dark-bg-primary',
                'bg-opacity-75 backdrop-filter backdrop-blur-lg',
                'dark:bg-opacity-75 dark:backdrop-filter dark:backdrop-blur-lg',
                'border-b dark:border-black-900',
                'shadow-lg shadow-gray-200/40 dark:shadow-black-900/50',
                'rounded-3xl',
              )}
            >
              {mainNavItems.map(mainNavItem => {
                const { to, label } = mainNavItem;
                if (!shouldRenderNavItem(mainNavItem)) return null;

                return (
                  <Link
                    key={`mobile-${label}`}
                    onClick={() => setMobileNavOpen(false)}
                    to={to}
                    className={cx('py-4 px-6', 'flex items-center space-x-4')}
                  >
                    <Text variant="label">{label}</Text>
                  </Link>
                );
              })}
              <hr />

              {secondaryNavItems.map(secondaryNavItem => {
                const { icon, onClick, label, to } = secondaryNavItem;
                if (!shouldRenderNavItem(secondaryNavItem)) return null;

                if (label === 'Profile') {
                  return (
                    <div key="mobile-profile">
                      <hr />
                      <InteractiveWrap
                        to={to}
                        onClick={() => setMobileNavOpen(false)}
                        className={cx('py-4 px-6', 'flex items-center space-x-4')}
                      >
                        <Avatar
                          src={currentUser?.image}
                          alt={currentUser?.name}
                          size="xs"
                          className="shadow-sm border border-light-bg-secondary dark:border-dark-fg-tertiary"
                        />
                        <Text variant="label" size="sm">
                          {currentUser?.name}
                        </Text>
                      </InteractiveWrap>
                    </div>
                  );
                }

                return (
                  <Link
                    key={`mobile-${label}`}
                    onClick={onClick}
                    to={to}
                    className={cx('py-4 px-6', 'flex items-center space-x-4')}
                  >
                    <Icon name={icon} size="sm" color="primary" weight="duotone" />
                    <Text variant="label" size="sm">
                      {label}
                    </Text>
                  </Link>
                );
              })}
            </nav>
          </Transition>
        </div>
      </header>
    </>
  );
}
