import { api, q } from 'config/api';

export default function useDevicePayment(id, overrides) {
  return q.useQuery({
    queryKey: ['device', id, 'payment'],
    queryFn: () =>
      api(
        `query devicePayment($id: ID!) {
          device(id: $id) {
            id
            name
            subscriptionStatus
          }
          currentBillingAddress {
            name
            country
            state
            city
            zip
            line1
            line2
          }
          paymentMethods {
            id
            last4
            default
            processor
            expiration {
              month
              year
            }
          }
        }`,
        { id },
      ),
    refetchInterval: 10000,
    enabled: !!id,
    ...overrides,
  });
}
