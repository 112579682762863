import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import DeviceSubscriptionDetails from 'components/device-subscription-details';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';

export default function DeviceSubscription() {
  useTitle('Subscribe to Device | Optra');
  const { deviceId } = useParams();

  return (
    <ModalInner>
      <ModalTitle title="Subscribe to Device" icon="CreditCard" />
      <DeviceSubscriptionDetails deviceId={deviceId} nextPath="../payment" />
    </ModalInner>
  );
}
