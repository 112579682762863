import cx from 'classnames';

import { Icon, Spinner } from '@optra/kit';

import skillIcons from 'config/skill-icons';

const WRAPPER_SIZES = {
  xs: ['w-6', 'h-6', 'rounded'],
  sm: ['w-10', 'h-10', 'rounded'],
  md: ['w-16', 'h-16', 'rounded-lg'],
  lg: ['w-24', 'h-24', 'rounded-2xl'],
};

const ICON_SIZES = {
  xs: 'sm',
  sm: 'md',
  md: 'lg',
  lg: 'xl',
};

export default function SkillIcon({
  color,
  icon,
  iconUrl,
  size = 'md',
  fetching = false,
  circle,
  className,
}) {
  if (fetching) {
    return (
      <div
        className={cx(
          'flex items-center justify-center bg-light-fg-secondary dark:bg-dark-fg-secondary text-white',
          WRAPPER_SIZES[size],
          className,
        )}
      >
        <Spinner size={ICON_SIZES[size]} />
      </div>
    );
  }

  return (
    <div
      className={cx(
        'relative',
        'flex items-center justify-center flex-shrink-0',
        'outline-1 outline-gray-300 dark:outline-black-900',
        'shadow-md shadow-gray-300 dark:shadow-black-900',
        'overflow-hidden',
        WRAPPER_SIZES[size],
        circle && 'rounded-[100%]',
        className,
      )}
      style={{
        backgroundColor: color || '#00C425',
        backgroundImage: iconUrl ? `url(${iconUrl})` : undefined,
        backgroundSize: iconUrl ? 'cover' : undefined,
        backgroundRepeat: 'no-repeat',
      }}
    >
      {!iconUrl && (
        <Icon
          name={skillIcons[icon] ? skillIcons[icon] : skillIcons['skill']}
          color="white"
          size={ICON_SIZES[size]}
          weight="duotone"
        />
      )}
      {/* {!iconUrl && <Icon name={skillIcons[icon]} color="white" size={ICON_SIZES[size]} />} */}
    </div>
  );
}
