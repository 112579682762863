import { isEqual, isString, get, set, isNumber, isBoolean } from 'lodash';
import qs from 'qs';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRouteQueryParams, parseSearchParams } from 'hooks';

export default function useRouteQueryState(ops = {}, defaultState) {
  let path;
  let method;
  if (isString(ops)) {
    path = ops;
    method = 'replace';
  } else {
    path = ops.path;
    method = ops.method || 'replace'; // oneOf [push, replace]
  }

  const isInitialRender = useRef(true);
  const location = useLocation();
  const navigate = useNavigate();
  const initialRouterQueryState = useRouteQueryParams();
  const initialState = get(initialRouterQueryState, path, defaultState);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const currentRouterQueryState = parseSearchParams(location.search);
    const proposedRouterQueryState = {
      ...currentRouterQueryState,
      ...set({}, path, state),
    };
    if (!isEqual(proposedRouterQueryState, currentRouterQueryState)) {
      navigate(
        `${location.pathname}${qs.stringify(proposedRouterQueryState, {
          addQueryPrefix: true,
          encoder(str, defaultEncoder, charset, type) {
            if (type === 'key') return str;
            if (isNumber(str)) {
              return `n(${str})`;
            }
            if (isBoolean(str)) {
              return `b(${str})`;
            }
            return defaultEncoder(str, defaultEncoder, charset, type);
          },
        })}`,
        { replace: method === 'replace' },
      );
    }
    // eslint-disable-next-line
  }, [state]);

  // React to external changes made to the search terms
  // useEffect(() => {
  //   if (!isEqual(state, initialState)) {
  //     console.log('ran');
  //     // Skip state change effect to avoid an infinite loop of toggling state
  //     isInitialRender.current = true;
  //     setState(initialState);
  //   }
  //   // eslint-disable-next-line
  // }, [initialState]);

  return [state, setState];
}
