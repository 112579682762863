import { last } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Routes, Route, Navigate, Outlet, useParams, Link, useNavigate } from 'react-router-dom';

import { Card, Button, Icon, Text, Heading } from '@optra/kit';

import Message from 'components/message';
import Modal from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillIcon from 'components/skill-icon';
import { api, q, useOnSuccess } from 'config/api';
import { usePathActive } from 'hooks';
import isFile from 'lib/is-file';
import useParseFileUrl from 'lib/use-parse-file-url';
import MarketplaceDetailGallery from 'modals/marketplace-detail-gallery';
import PublishSkillCompatibility from 'modals/publish-skill-compatibility';
import PublishSkillDescription from 'modals/publish-skill-description';
import PublishSkillIcon from 'modals/publish-skill-icon';
import PublishSkillImages from 'modals/publish-skill-images';
import PublishSkillPreview from 'modals/publish-skill-preview';
import PublishSkillSubmitted from 'modals/publish-skill-submitted';
import PublishSkillVersion from 'modals/publish-skill-version';
import { usePublishSkill, useSignedUpload } from 'queries';

function SubRoutes() {
  return (
    <Routes>
      <Route
        path="icon"
        element={
          <Modal>
            <PublishSkillIcon />
          </Modal>
        }
      />
    </Routes>
  );
}

export default function PublishSkill() {
  const Form = useForm({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      versionId: '',
      name: '',
      icon: {
        icon: 'action',
        color: '#00C425',
        iconUrl: null,
      },
      summary: '',
      description: '',
      releaseNotes: '',
      compatibleFirmwareVersions: '',
      compatibleDeviceModels: {
        vz1000: false,
        vz5000: false,
        compute550: false,
        cx1000: false,
      },
      licenseUrl: null,
      manualUrl: null,
      screenshotUrls: [null, null, null],
    },
  });
  const { skillId } = useParams();
  const skillQuery = usePublishSkill(skillId);
  const { data: skillData, isLoading, isSuccess, error } = skillQuery;

  useOnSuccess(
    () => {
      const { skill, marketplaceSkill } = skillData;
      const publishableVersions = skill?.versions?.data?.filter(
        v => v.publicationState === 'publishable',
      );
      const latestPublishableVersion = publishableVersions?.[0];

      const latestPublishedVersion = marketplaceSkill?.versions?.data?.find?.(
        v => v.publicationState !== 'publishable',
      );

      if (publishableVersions?.length < 1) {
        setGeneralError('No publishable versions available');
      }

      Form.reset({
        versionId: latestPublishableVersion?.id,
        name: latestPublishedVersion?.name || skill?.name,
        icon: {
          icon: latestPublishedVersion?.icon || skill?.icon || 'action',
          color: latestPublishedVersion?.color || skill?.color || '#00C425',
          iconUrl: latestPublishedVersion?.iconUrl || skill?.iconUrl,
        },
        summary: latestPublishedVersion?.summary || '',
        description: latestPublishedVersion?.description || '',
        releaseNotes: '',
        compatibleFirmwareVersions: latestPublishedVersion?.compatibleFirmwareVersions || '',
        compatibleDeviceModels: {
          vz1000: latestPublishedVersion?.compatibleDeviceModels?.includes?.('vz1000') || false,
          vz5000: latestPublishedVersion?.compatibleDeviceModels?.includes?.('vz5000') || false,
          compute550:
            latestPublishedVersion?.compatibleDeviceModels?.includes?.('compute550') || false,
          cx1000: latestPublishedVersion?.compatibleDeviceModels?.includes?.('cx1000') || false,
        },
        licenseUrl: latestPublishedVersion?.licenseUrl || null,
        manualUrl: latestPublishedVersion?.manualUrl || null,
        screenshotUrls: [
          latestPublishedVersion?.screenshotUrls?.[0] || null,
          latestPublishedVersion?.screenshotUrls?.[1] || null,
          latestPublishedVersion?.screenshotUrls?.[2] || null,
        ],
      });
    },
    { isSuccess },
    [Form, skillData],
  );

  const pathRendered = {
    version: usePathActive({ to: 'version' }),
    description: usePathActive({ to: 'description' }),
    compatibility: usePathActive({ to: 'compatibility' }),
    images: usePathActive({ to: 'images' }),
  };
  const currentPath = Object.entries(pathRendered).filter(([k, v]) => v)?.[0]?.[0];
  const pathNextMap = {
    version: 'description',
    description: 'compatibility',
    compatibility: 'images',
    images: 'preview',
  };
  const pathPrevMap = Object.assign(
    {},
    ...Object.entries(pathNextMap).map(([k, v]) => ({ [v]: k })),
  );

  const formValues = Form.watch();
  const selectedVersion = skillData?.skill?.versions?.data?.find(
    v => v.id === formValues.versionId,
  );
  const iconUrl = useParseFileUrl(formValues.icon?.iconUrl);
  const licenseUrl = useParseFileUrl(formValues.licenseUrl);
  const manualUrl = useParseFileUrl(formValues.manualUrl);
  const screenshotUrls = [
    useParseFileUrl(formValues.screenshotUrls?.[0]),
    useParseFileUrl(formValues.screenshotUrls?.[1]),
    useParseFileUrl(formValues.screenshotUrls?.[2]),
  ];
  const proposedMarketplaceSkill = {
    ...formValues,
    ...formValues.icon,
    iconUrl,
    licenseUrl,
    manualUrl,
    latestVersion: {
      ...selectedVersion,
      releaseNotes: formValues.releaseNotes,
      compatibleDeviceModels: Object.entries(formValues.compatibleDeviceModels || {})
        .filter(([k, v]) => v)
        .map(([k]) => k),
    },
    developerProfile:
      skillData?.marketplaceSkill?.developerProfile || skillData?.currentDeveloperProfile,
    screenshotUrls: screenshotUrls.filter(f => f),
  };

  const navigate = useNavigate();
  const [uploadIcon] = useSignedUpload({ type: 'skillImage' });
  const [uploadLicense] = useSignedUpload({ type: 'marketplaceSkillLicense' });
  const [uploadManual] = useSignedUpload({ type: 'skillManual' });
  const [uploadScreenShot1] = useSignedUpload({ type: 'skillImage' });
  const [uploadScreenShot2] = useSignedUpload({ type: 'skillImage' });
  const [uploadScreenShot3] = useSignedUpload({ type: 'skillImage' });
  const [generalError, setGeneralError] = useState();
  const qc = q.useQueryClient();
  const publishMarketplaceSkill = q.useMutation({
    mutationFn: form =>
      api(
        `mutation publishMarketplaceSkill($form: publishMarketplaceSkillForm!) {
          marketplaceSkill: publishMarketplaceSkill(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['skill', skillId] });
      qc.invalidateQueries({ queryKey: ['marketplaceSkills'] });
    },
    onError(err) {
      setGeneralError(err.message);
    },
  });
  const handleSubmit = Form.handleSubmit(async form => {
    setGeneralError(null);
    navigate('submitted');
    const formValid = await Form.trigger();
    if (!formValid) throw new Error('Form invalid!');
    // Upload set images in parallel
    const [icon, license, manual, screenshot1, screenshot2, screenshot3] = await Promise.all([
      isFile(form.icon?.iconUrl)
        ? uploadIcon(form.icon.iconUrl, {
            extension: last(form.icon.iconUrl.name.split('.')),
            name: skillId,
          })
        : { url: form.icon?.iconUrl },
      isFile(form.licenseUrl)
        ? uploadLicense(form.licenseUrl, {
            extension: last(form.licenseUrl.name.split('.')),
            name: form.licenseUrl.name.split('.').slice(0, -1).join('.'),
          })
        : { url: form.licenseUrl },
      isFile(form.manualUrl)
        ? uploadManual(form.manualUrl, {
            extension: last(form.manualUrl.name.split('.')),
            name: form.manualUrl.name.split('.').slice(0, -1).join('.'),
          })
        : { url: form.manualUrl },
      isFile(form.screenshotUrls?.[0])
        ? uploadScreenShot1(form.screenshotUrls[0], {
            extension: last(form.screenshotUrls[0].name.split('.')),
            name: `${skillId}-0`,
          })
        : { url: form.screenshotUrls?.[0] },
      isFile(form.screenshotUrls?.[1])
        ? uploadScreenShot2(form.screenshotUrls[1], {
            extension: last(form.screenshotUrls[1].name.split('.')),
            name: `${skillId}-1`,
          })
        : { url: form.screenshotUrls?.[1] },
      isFile(form.screenshotUrls?.[2])
        ? uploadScreenShot3(form.screenshotUrls[2], {
            extension: last(form.screenshotUrls[2].name.split('.')),
            name: `${skillId}-2`,
          })
        : { url: form.screenshotUrls?.[2] },
    ]);
    const compatibleDeviceModels = Object.entries(form.compatibleDeviceModels)
      .filter(([k, v]) => v)
      .map(([k]) => k);
    const preparedForm = {
      skillVersionId: form.versionId,
      name: form.name,
      color: form.icon?.color,
      icon: form.icon?.icon,
      iconUrl: icon?.url,
      summary: form.summary,
      description: form.description,
      screenshotUrls: [screenshot1.url, screenshot2.url, screenshot3.url],
      licenseUrl: license.url,
      manualUrl: manual.url,
      compatibleFirmwareVersions: form.compatibleFirmwareVersions,
      compatibleDeviceModels,
      releaseNotes: form.releaseNotes,
      // tags,
      // price,
    };
    return publishMarketplaceSkill.mutateAsync(preparedForm);
  });

  const outletContext = [Form, skillQuery, proposedMarketplaceSkill];

  return (
    <Modal
      showBackButton={!!pathPrevMap[currentPath]}
      handleBack={() => {
        navigate(pathPrevMap[currentPath]);
      }}
    >
      <Routes>
        <Route index element={<Navigate replace to="version" />} />
        <Route
          element={
            <ModalInner>
              <ModalTitle title="Publish Skill" icon="CloudArrowUp" loading={isLoading} />
              <ModalBody>
                {error && (
                  <Message variant="danger" title="Couldn't Load Skill">
                    {error.message}
                  </Message>
                )}
                {generalError && (
                  <Message variant="danger" title="Unable to publish">
                    {generalError}
                  </Message>
                )}
                <div className="space-y-4">
                  <Card
                    variant="secondary"
                    noPadding
                    className="divide-y divide-light-fg-tertiary dark:divide-dark-fg-tertiary"
                  >
                    <div className="flex flex-col items-center justify-center py-5">
                      <SkillIcon
                        size="lg"
                        color={proposedMarketplaceSkill.color}
                        icon={proposedMarketplaceSkill.icon}
                        iconUrl={proposedMarketplaceSkill.iconUrl}
                        className="mb-2"
                        fetching={isLoading}
                      />
                      <div className="text-center">
                        <Heading>{proposedMarketplaceSkill.name || 'Loading…'}</Heading>
                      </div>
                      {proposedMarketplaceSkill?.developerProfile?.name && (
                        <div className="text-center">
                          <Text size="sm" color="muted">
                            by {proposedMarketplaceSkill?.developerProfile?.name}
                          </Text>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-1 items-center justify-between gap-3 px-4 py-5 relative">
                      <Icon name="PencilSimple" color="primary" />
                      <Text className="flex-1">Change Name or Icon</Text>
                      <Icon name="CaretRight" weight="line" />
                      <Link to={`${currentPath}/icon`} className="absolute inset-0" />
                    </div>
                  </Card>
                  <Outlet context={outletContext} />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  size="xl"
                  disabled={generalError}
                  onClick={async () => {
                    const formValid = await Form.trigger();
                    if (formValid)
                      navigate(pathNextMap[currentPath], { state: { showBackButton: true } });
                  }}
                >
                  {currentPath === 'images' ? 'Preview' : 'Next'}
                </Button>
              </ModalFooter>
            </ModalInner>
          }
        >
          <Route
            path="version/*"
            element={
              <>
                <PublishSkillVersion
                  form={Form}
                  isLoading={isLoading}
                  versions={(skillData?.skill?.versions?.data || []).filter(
                    v => v.publicationState === 'publishable',
                  )}
                />
                <SubRoutes />
              </>
            }
          />
          <Route
            path="description/*"
            element={
              <>
                <PublishSkillDescription form={Form} skill={skillQuery} />
                <SubRoutes />
              </>
            }
          />
          <Route
            path="compatibility/*"
            element={
              <>
                <PublishSkillCompatibility form={Form} skill={skillQuery} />
                <SubRoutes />
              </>
            }
          />
          <Route
            path="images/*"
            element={
              <>
                <PublishSkillImages form={Form} />
                <SubRoutes />
              </>
            }
          />
        </Route>
        <Route element={<Outlet context={outletContext} />}>
          <Route
            path="preview/*"
            element={
              <>
                <PublishSkillPreview handleSubmit={handleSubmit} />
                <Outlet context={outletContext} />
              </>
            }
          >
            <Route index element={null} />
            <Route
              path="gallery"
              element={<MarketplaceDetailGallery marketplaceSkill={proposedMarketplaceSkill} />}
            />
          </Route>
          <Route path="submitted" element={<PublishSkillSubmitted error={generalError} />} />
        </Route>
      </Routes>
    </Modal>
  );
}
